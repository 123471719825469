<template>
  <div>
    <SearchResultsLoader v-if="loading" />
    <div class="flex" v-else>
      <div class="lg:min-w-90 max-w-90 border-r border-gray-100">
        <Filters :open="isFilterOpened" :table="table" @close="closeFilter" />
      </div>

      <div class="lg:mx-6 pt-10 p-4 md:p-6 flex-1">
        <div class="lg:max-w-196">
          <div class="flex items-center mb-4 md:mb-2">
            <h1 class="text-2xl md:text-3xl md:leading-11 text-secondary-900 font-bold">
              Recent Searches
            </h1>
          </div>
          <p class="text-sm md:text-md leading-5 text-secondary-700 mt-4 md:mt-2">
            This page contains all recent searches you conducted, sorted by date of search. Each
            card represents a separate search and contains the details of the search, as well as
            information on any saves candidates. You can go to the search results by clicking View,
            and save the search in Saved Searches by clicking the Save button.
          </p>
          <div class="lg:hidden mt-6 md:mt-4">
            <Button
              variant="outline-primary"
              class="flex justify-center w-full md:w-auto"
              @click="openFilter"
            >
              <Icon name="svg-filter" class="size-5 me-2" />
              Filter
            </Button>
          </div>
        </div>
        <div class="mt-6">
          <div class="grid gap-4 md:gap-6 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3">
            <template v-for="row in table.getRowModel().rows" :key="row.id">
              <RecentSearchCard :data="row.original" @saveSearchError="savedSearchError = true" />
            </template>
          </div>
          <div class="flex flex-row w-full mt-6">
            <Pagination
              v-model="currentPage"
              :records="Object.entries(table.getPaginationRowModel().rowsById).length"
              :per-page="9"
              @paginate="handlePagination"
              :options="paginationOptions"
            />
          </div>
        </div>
      </div>
    </div>
    <Message
      v-if="savedSearchError"
      class="fixed bottom-0 w-full h-15"
      type="error"
      message="Failed to save search, please contact support"
      :timeout="5000"
      @timeout="savedSearchError = false"
    />
  </div>
</template>

<script setup lang="ts">
import RecentSearchCard from '@/views/Card/Search/RecentSearchCard.vue'

import { ref, onMounted } from 'vue'
import axios from 'axios'
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useVueTable
} from '@tanstack/vue-table'

import SearchResultsLoader from '@/components/Results/SearchResultsLoader.vue'
import Filters from '@/views/Searches/Components/Filters.vue'
import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
// import SavedSearchCard from '@/views/Card/Search/SavedSearchCard.vue'
import Message from '@/components/Message/Message.vue'
import NewPagination from '@/components/NewPagination.vue'
import Pagination from 'v-pagination-3'

import type { SavedSearch } from './types'

const loading = ref<boolean>(true)
const savedSearchError = ref<boolean>(false)
const isFilterOpened = ref<boolean>(false)
const tableData = ref<SavedSearch[]>([])
const error = ref<boolean>(false)
const currentPage = ref(1)
const paginationOptions = ref({
  template: NewPagination,
  chunk: 9
})

onMounted(async () => {
  loading.value = true
  try {
    const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/recent-searches`)
    tableData.value = res.data
  } catch (err) {
  } finally {
    loading.value = false
  }
})

const handlePagination = (page: number) => {
  table.setPageIndex(page - 1)
  currentPage.value = page
}

const openFilter = () => {
  isFilterOpened.value = true
}

const closeFilter = () => {
  isFilterOpened.value = false
}

const columnHelper = createColumnHelper<SavedSearch>()
const includeExcludeFilter = (
  row,
  columnId: string,
  filterValue: { label: string; type: string }[]
) => {
  if (filterValue.length === 0) {
    return true
  }

  if (filterValue.some((filter) => filter.type === 'include')) {
    if (typeof row.original[columnId] === 'string') {
      return filterValue.some((filter) => {
        return (
          filter.type === 'include' &&
          row.original[columnId].toLowerCase() === filter.label.toLowerCase()
        )
      })
    } else if (Array.isArray(row.original[columnId])) {
      return filterValue.some(
        (filter) => filter.type === 'include' && row.original[columnId].includes(filter.label)
      )
    } else if (row.original[columnId] === null) {
      return false
    }
  } else {
    if (typeof row.original[columnId] === 'string') {
      let result = true
      filterValue.forEach((filter) => {
        if (
          filter.type === 'exclude' &&
          row.original[columnId].toLowerCase() === filter.label.toLowerCase()
        ) {
          result = false
        }
      })
      return result
    } else if (Array.isArray(row.original[columnId])) {
      let result = true
      filterValue.forEach((filter) => {
        if (filter.type === 'exclude' && row.original[columnId].includes(filter.label)) {
          result = false
        }
      })
      return result
    } else if (row.original[columnId] === null) {
      return true
    }
  }
}

const locationFilter = (row, columnId: string, filterValue: { label: string; type: string }[]) => {
  if (filterValue.length === 0) {
    return true
  }

  if (filterValue.some((filter) => filter.type === 'include')) {
    if (Array.isArray(row.original[columnId])) {
      console.log(row.original[columnId], filterValue)
      return filterValue.some(
        (filter) =>
          filter.type === 'include' &&
          row.original[columnId].some(
            (cellLocation) =>
              cellLocation.value.city === filter.value.city &&
              cellLocation.value.state === filter.value.state &&
              cellLocation.value.country === filter.value.country
          )
      )
    } else {
      return false
    }
  } else {
    if (Array.isArray(row.original[columnId])) {
      let result = true
      filterValue.forEach((filter) => {
        if (
          filter.type === 'exclude' &&
          row.original[columnId].some(
            (cellLocation) =>
              cellLocation.value.city === filter.value.city &&
              cellLocation.value.state === filter.value.state &&
              cellLocation.value.country === filter.value.country
          )
        ) {
          result = false
        }
      })
      return result
    } else if (row.original[columnId] === null) {
      return true
    }
  }
}

const minExpFilterFunction = (row, columnId, filterValue) => {
  return filterValue <= row.original[columnId]
}

const columns = [
  columnHelper.accessor('search_type', {}),
  columnHelper.accessor('title', {
    enableColumnFilter: true,
    filterFn: includeExcludeFilter
  }),
  columnHelper.accessor('archetype_name', {}),
  columnHelper.accessor('role_experience', {
    enableColumnFilter: true,
    filterFn: minExpFilterFunction
  }),
  columnHelper.accessor('career_experience', {
    enableColumnFilter: true,
    filterFn: minExpFilterFunction
  }),
  columnHelper.accessor('locations', {
    enableColumnFilter: true,
    filterFn: locationFilter
  }),
  columnHelper.accessor('industries', {
    enableColumnFilter: true,
    filterFn: includeExcludeFilter
  }),
  columnHelper.accessor('created_at', {}),
  columnHelper.accessor('total_results', {}),
  columnHelper.accessor('total_shortlisted', {})
]

const table = useVueTable({
  data: tableData,
  initialState: {
    pagination: {
      pageIndex: 0, //custom initial page index
      pageSize: 9 //custom default page size
    }
  },
  columns: columns,
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(), //client-side filtering
  getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
  getSortedRowModel: getSortedRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  autoResetPageIndex: false
})
</script>
