import { captureException } from '@sentry/vue'
import type { SearchPayload, SearchResults } from './types'
import { defineStore } from 'pinia'
import axios from 'axios'

interface IState {
  searchResults: {}[] | null
  searchId: number | null
  shortlist: string[]
  searchPayload: SearchPayload | null
  searchType: 'url' | 'title' | null
  linkedinProfileData: any
  savedSearch: boolean
}

export const useSearchStore = defineStore('search', {
  state: (): IState => ({
    searchResults: null,
    searchId: null,
    savedSearch: false,
    advancedSearch: false,
    shortlist: [],
    searchPayload: null,
    searchType: null,
    linkedinProfileData: null
  }),

  getters: {
    isAdvancedSearch: (state) => state.advancedSearch,
  },

  actions: {
    async fetchSearchResults(searchId: number) {
      try {
        const res = await axios.get(
          `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/${searchId}`
        )

        if (res.data.search_type === 'url') {
          this.setSearchState(res.data, res.data.searchParams)
        }
        if (res.data.search_type === 'title') {
          this.setSearchState(res.data, res.data.archetypeData)
        }
      } catch (err) {
        captureException(err)
        throw err
      }
    },
    setSearchState(data: SearchResults[] | null, searchPayload: SearchPayload | null) {
      this.searchResults = data
      this.searchId = data?.search_id
      this.savedSearch = data?.saved

      this.searchType = data?.search_type
      this.populateShortlist()
      this.setSearchPayload(searchPayload)
    },
    setSearchId(id: number | null) {
      this.searchId = id
    },
    populateShortlist() {
      if (this.searchResults) {
        this.shortlist = []
        if (this.searchResults.results && this.searchResults.results.length > 0) {
          this.searchResults.results.forEach((candidate) => {
            if (candidate.shortlisted) {
              this.addCandidateToShortlist(candidate.id)
            }
          })
        }
      }
    },
    addCandidateToShortlist(id: number) {
      this.shortlist.push(id)
    },
    setSearchPayload(payload: SearchPayload) {
      this.searchPayload = payload
    },
    setAdvanceSearch(show: boolean) {
      this.advancedSearch = show;
    }
  }
})
