<template>
  <div class="flex flex-col items-start p-0 w-72">
    <label
      :for="name || `form-input-${$.uid}`"
      :class="[{ 'text-secondary-300': disabled, 'text-secondary-600': !disabled }, labelClass]"
      class="text-sm font-semibold"
    >
      {{ label }}
    </label>

    <Multiselect
      ref="multiselectElement"
      :id="name || `form-input-${$.uid}`"
      :mode="mode"
      :name="name"
      :searchable="searchable"
      :options="
        apiUrl
          ? async function (query) {
              return await search(query)
            }
          : options
      "
      :show-options="
        hideOptionIfSearchEmpty
          ? options
            ? options && options.length > 0
            : hideOptionIfSearchEmpty && typedValue !== ''
          : true
      "
      :required="required"
      :can-clear="clearable"
      :disabled="disabled"
      :placeholder="!disabled ? placeholder : ''"
      v-model="value"
      :hideSelected="false"
      :closeOnSelect="closeOnSelect"
      :clearOnBlur="clearOnBlur"
      :clearOnSearch="!!apiUrl"
      :clearOnSelect="clearOnSelect"
      :resolveOnLoad="!!apiUrl"
      :filter-results="!apiUrl"
      :min-chars="1"
      :delay="delay ?? -1"
      value-prop="label"
      :object="saveValuesAsObjects"
      autocomplete="hack-to-turn-of-autocomplete-in-chrome"
      track-by="value"
      :classes="{
        container:
          'multiselect-custom relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-500 rounded-lg bg-white text-base leading-snug outline-none group hover:bg-secondary-100 ' +
          inputClass,
        wrapper:
          'relative mx-auto w-full flex items-center justify-between box-border cursor-pointer outline-none p-1',
        options: 'flex flex-col items-start px-2 py-0.5 gap-1.5 overflow-y-scroll',
        option: 'flex justify-between items-center p-2 px-3 w-full',
        optionSelected: 'bg-primary-100 border border-primary-400 rounded-lg',
        optionSelectedPointed:
          'bg-primary-100 border border-primary-400 rounded-lg hover:bg-primary-200',
        optionPointed: 'text-gray-800 bg-gray-150 rounded-lg hover:bg-secondary-100',
        tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 px-2 min-w-0 rtl:pl-0 rtl:pr-2 overflow-hidden gap-2',
        tag: 'flex flex-row items-center p-0 px-2 bg-primary-50 rounded font-medium text-sm text-primary-700 pr-1',
        dropdown:
          'max-h-60 absolute -left-px -right-px transform bottom-[-5px] translate-y-full border border-gray-500 rounded-lg mt-2 z-50 bg-white flex flex-col overflow-hidden ' +
          dropdownClass,
        dropdownHidden: 'hidden',
        containerDisabled: 'border-secondary-200',
        tagsSearch:
          'absolute inset-0 border-0 outline-none focus:ring-0 focus:appearance-none p-0 text-sm leading-6 font-sans box-border w-full group-hover:bg-secondary-100',
        search:
          'inset-0 border-0 outline-none focus:ring-0 focus:appearance-none px-3 py-1 text-sm leading-6 rounded-lg w-full group-hover:bg-secondary-100',
        fakeInput:
          'bg-transparent border-0 h-px text-[0] absolute -bottom-px left-0 !ring-transparent ring-offset-transparent w-full !outline-none !shadow-none p-0',
        // singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
        clear:
          'pr-3.5 relative z-10 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
        spinner: 'hidden'
      }"
      @search-change="(val) => (typedValue = val)"
      @change="change"
      @open="resetScroll"
    >
      <template #spinner>
        <div
          v-if="loadingOptions"
          :class="
            'max-h-60 bottom-[-5px] absolute -left-px -right-px transform translate-y-full border border-gray-500 rounded-lg mt-2 overflow-y-hidden z-50 bg-white flex flex-col' +
            dropdownClass
          "
          tabindex="-1"
        >
          <InputLoader class="m-auto my-5" />
        </div>
      </template>

      <template #option="{ option, isSelected }">
        <span
          class="flex flex-row order-1 font-normal font-400 text-sm text-secondary-900 gap-2"
          :class="{ 'font-semibold': option.isRecentSearched }"
        >
          {{ option.label }}

          <Icon class="w-3 h-3" name="svg-eye" v-if="option.isRecentSearched" />
        </span>
        <Checkbox :checked="isSelected(option)" class="order-2 hidden"></Checkbox>
        <Icon
          :name="isSelected(option) ? 'input-checked' : 'input-unchecked'"
          class="order-2 w-5 h-5"
        ></Icon>
      </template>
      <template #caret="data">
        <Icon
          :name="data.isOpen ? 'input-arrow-up' : 'input-arrow-down'"
          class="text-primary-700 h-4 w-4 right-[10px]"
          :class="{ 'pointer-events-none': data.isOpen }"
        />
      </template>
    </Multiselect>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch, nextTick } from 'vue'
import Multiselect from '@vueform/multiselect'
import { captureException } from '@sentry/vue'
import axios from 'axios'

import Icon from '@/components/Icon/Icon.vue'
import Checkbox from '@/components/Inputs/Checkbox.vue'
import InputLoader from '@/components/Inputs/InputLoader.vue'

interface IProps {
  mode?: string
  label?: string
  labelClass?: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  name?: string | number
  isValid?: boolean
  error?: string | string[]
  clearable?: boolean
  options: string[]
  searchable?: boolean
  apiUrl?: string
  paramName?: string
  valueKey?: string
  labelKey?: string
  delay?: number
  queryParams?: Record<string, string>
  selectedValue?: string | null | { label: string; value: string } | string[]
  closeOnSelect?: boolean
  clearOnSelect?: boolean
  saveValuesAsObjects?: boolean
  hideOptionIfSearchEmpty?: boolean
  inputClass?: string
  dropdownClass?: string
  clearOnBlur?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  clearable: false,
  searchable: true,
  mode: 'tags',
  placeholder: 'Type here',
  paramName: 'query',
  selectedValue: null,
  closeOnSelect: false,
  clearOnSelect: false,
  saveValuesAsObjects: false,
  clearOnBlur: false
})

const emit = defineEmits(['change'])

const multiselectElement = ref(null)
const value = ref(null)
const typedValue = ref('')

watch(
  () => props.selectedValue,
  (newVal) => {
    value.value = newVal
  },
  { immediate: true }
)

const loadingOptions = ref(false)

const search = async (query) => {
  if (query) {
    loadingOptions.value = true

    try {
      const apiUrl = new URL(props.apiUrl)
      apiUrl.searchParams.set(props.paramName, query)

      if (props.queryParams) {
        Object.entries(props.queryParams).forEach(([key, value]) => {
          apiUrl.searchParams.set(key, value)
        })
      }

      const res = await axios.get(apiUrl.toString())
      setTimeout(resetScroll, 100);

      if (res.status === 200) {
        return res.data.map((item) => {
          return {
            value: item[props.valueKey || 'value'],
            label: item[props.labelKey || 'label']
          }
        })
      } else {
        return []
      }
    } catch (err) {
      console.error(err)
      captureException(err)
    } finally {
      loadingOptions.value = false
    }
  }
}

function resetScroll() {
  nextTick(() => {
    const dropdown = multiselectElement.value?.$refs.dropdown.querySelector('ul')
    if (dropdown.children.length) {
      dropdown.scrollTop = 0;
    }
  })
}

const change = (data) => {
  emit('change', data)
}

defineExpose({
  multiselectElement
})
</script>

<style scoped>
.multiselect-custom ::-webkit-scrollbar {
  width: 4px;
}

.multiselect-custom ::-webkit-scrollbar-track {
  background: #edeef1;
  border-radius: 50px;
}

.multiselect-custom ::-webkit-scrollbar-thumb {
  background: #b3bac6;
  border-radius: 24px;
}
</style>

<style src="../../../../node_modules/@vueform/multiselect/themes/default.css"></style>
