<template>
  <div class="mt-10 mb-10 flex flex-col gap-10">
    <div class="flex gap-4">
      <EssentialSubscriptionPlan @upgradeNow="openAgreementModal" />
      <ProfessionalSubscriptionPlan @upgradeNow="openAgreementModal" />
      <TeamsSubscriptionPlan />
    </div>
    <div>
      <h2 class="text-3xl font-bold mb-5">Annual Plans</h2>
      <h3 class="text-xl font-bold mb-5">Save 20%</h3>
      <div class="flex gap-4">
        <EssentialSubscriptionPlanAnnual @upgradeNow="openAgreementModal" />
        <ProfessionalSubscriptionPlanAnnual @upgradeNow="openAgreementModal" />
        <TeamsSubscriptionPlanAnnual @upgradeNow="openAgreementModal" />
      </div>
    </div>
    <SubscriptionAgreementModal
      v-if="showAgreementModal"
      @close="showAgreementModal = false"
      @agree="openCheckout"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { initializePaddle } from '@paddle/paddle-js'
import { captureException } from '@sentry/vue'

import EssentialSubscriptionPlan from '@/views/User/Billing/Components/EssentialSubscriptionPlan.vue'
import ProfessionalSubscriptionPlan from '@/views/User/Billing/Components/ProfessionalSubscriptionPlan.vue'
import TeamsSubscriptionPlan from '@/views/User/Billing/Components/TeamsSubscriptionPlan.vue'
import SubscriptionAgreementModal from '@/views/User/Billing/Components/SubscriptionAgreementModal.vue'
import EssentialSubscriptionPlanAnnual from '@/views/User/Billing/Components/AnnualPlans/EssentialSubscriptionPlanAnnual.vue'
import ProfessionalSubscriptionPlanAnnual from '@/views/User/Billing/Components/AnnualPlans/ProfessionalSubscriptionPlanAnnual.vue'
import TeamsSubscriptionPlanAnnual from '@/views/User/Billing/Components/AnnualPlans/TeamsSubscriptionPlanAnnual.vue'
import { useUserStore } from '@/stores/user'

const emit = defineEmits(['subscriptionCreated'])

const store = useUserStore()

const paddle = ref()
const chosenSubscriptionType = ref<string | null>(null)
const chosenSubscriptionId = ref<string | null>(null)
const showAgreementModal = ref(false)

onMounted(() => {
  initializePaddle({
    environment: `${import.meta.env.VITE_PADDLE_ENVIRONMENT}`,
    token: `${import.meta.env.VITE_PADDLE_TOKEN}`,
    eventCallback: async function (data) {
      if (data.name == 'checkout.completed') {
        store.subscriptionStatus = 'active'
        store.subscriptionType = chosenSubscriptionType.value
        store.subscriptionEndsAt = null
        emit('subscriptionCreated')
      }
    }
  })
    .then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        paddle.value = paddleInstance
      }
    })
    .catch((error) => {
      captureException(error)
    })
})

const openCheckout = () => {
  showAgreementModal.value = false

  paddle.value?.Checkout.open({
    settings: { allowLogout: false },
    items: [{ priceId: chosenSubscriptionId.value, quantity: 1 }],
    customer: {
      name: store.getUserName,
      email: store.getUser?.email
      //   business: { name: user.organization }
    }
  })
}

const openAgreementModal = (paddleSubscriptionId: string, subscriptionType: string) => {
  chosenSubscriptionType.value = subscriptionType
  chosenSubscriptionId.value = paddleSubscriptionId
  showAgreementModal.value = true
}
</script>
