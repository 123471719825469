export const setLocationSearches = (countries, states, cities) => {
  setSearches('countries', countries)
  setSearches('states', states)
  setSearches('cities', cities)

  window.dispatchEvent(new CustomEvent('changed-recently-searches'))
}

export const setRecentLocations = (locations) => {
  const formattedLocations = locations.map((location) => `${location.value._id}`)

  setSearches('locations', formattedLocations)

  window.dispatchEvent(new CustomEvent('changed-recently-searches'))
}

export const getRecentLocationSearches = (key, count, include?) => {
  if (!localStorage.hasOwnProperty(key)) return [];

  const data = JSON.parse(localStorage.getItem(key))

  const filteredValues = Object.entries(data).filter(
    (item) =>
      item[1].count >= 1 &&
      (include !== undefined ? Object.values(include).includes(item[0]) : true)
  )

  if (filteredValues.length === 0) return []

  const sortedValues = Object.fromEntries(filteredValues.sort((a, b) => b[1].count - a[1].count))

  return Object.keys(sortedValues).slice(0, count).sort()
}

export const getLocationRecentSearches = (key, count, include?) => {
  if (!localStorage.hasOwnProperty(key)) return []

  const data = JSON.parse(localStorage.getItem(key))

  const filteredValues = Object.entries(data).filter(
    (item) =>
      item[1].count >= 1 &&
      (include !== undefined ? Object.values(include).includes(item[0]) : true)
  )

  if (filteredValues.length === 0) return []

  const sortedValues = Object.fromEntries(filteredValues.sort((a, b) => b[1].count - a[1].count))

  return Object.keys(sortedValues).slice(0, count).sort()
}

const setSearches = (key, data) => {
  const searches = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : {}

  data.map((item) => {
    if (searches[item]) {
      searches[item].count = ++searches[item].count
    } else {
      searches[item] = {
        count: 1
      }
    }
    searches[item].timestamp = Date.now()
  })

  localStorage.setItem(key, JSON.stringify(searches))
}
