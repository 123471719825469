<template>
  <div class="relative md:first:-mt-2">
    <BaseCandidateCard
      v-if="displayCandidate"
      :candidate-data="candidateData"
      @viewCandidate="toggleCandidateModal"
    >
      <template #buttons>
        <div class="flex flex-wrap flex-row md:flex-col gap-4 max-sm:mt-4 relative">
          <div class="flex items-center md:gap-3 max-sm:w-full max-sm:order-last">
            <RecentlyViewedButton
              last-viewed="12"
              :candidate-id="candidateData.original.profile_id"
              :display-at-first-time="true"
              tooltip-position="left"
              class="max-sm:hidden"
            />
            <Button variant="primary" class="w-full" @onClick="toggleCandidateModal">View</Button>
          </div>
          <div class="flex items-center">
            <ShortlistButton
              class="w-full"
              :candidate-id="candidateData.original.project_candidate_id"
              @shortlisted="$emit('shortlisted', $event)"
              @error="$emit('shortlistError', $event)"
            />
          </div>
          <div class="flex items-center">
            <Button
              variant="outline-danger"
              class="w-full"
              :loading="removingCandidateId === candidateData.original.project_candidate_id"
              @click="toggleRemoveSearchModal"
            >
              Remove
            </Button>
          </div>
          <div class="flex justify-center gap-3 md:order-last">
            <AddToProjectButton
              :projects="projects"
              :search-result-id="candidateData.original.id"
              title="Add to a Different Project"
              @addToProjectSuccess="$emit('addToProjectSuccess', true)"
              @addToProjectError="$emit('addToProjectError', $event)"
              @projectCreated="$emit('projectCreated', true)"
              @projectCreationError="$emit('projectCreationError', $event)"
            />
          </div>
          <CandidateViewModal
            v-if="showCandidateModal"
            class="max-sm:w-full max-sm:order-last"
            :candidate-id="candidateData.original.candidateId"
            :search-result-id="candidateData.original.id"
            :projects="projects"
            :is-project-page="true"
            :project-candidate-id="candidateData.original.project_candidate_id"
            @close="toggleCandidateModal"
            @addToProjectSuccess="$emit('addToProjectSuccess', false)"
            @projectCreated="$emit('projectCreated', false)"
            @shortlisted="$emit('shortlisted', $event)"
          />
        </div>
        <RemoveModal
          v-if="removeSearchModal"
          title="Remove candidate"
          description="This action is irreversible. Are you sure you want to remove this candidate from the project?"
          @close="closeRemoveSearchModal"
          @remove="
            () => {
              $emit('remove', props.candidateData.original.project_candidate_id)
              toggleRemoveSearchModal()
            }
          "
        />
      </template>
    </BaseCandidateCard>
  </div>
</template>
<script setup lang="ts">
import Button from '@/components/Buttons/Button.vue'

import { defineProps, ref } from 'vue'
import BaseCandidateCard from '@/components/Card/Candidate/BaseCandidateCard.vue'
import ShortlistButton from '@/views/Projects/ProjectsCandidatesPage/Components/ShortListButton.vue'
import CandidateViewModal from '@/components/Modals/Candidate/CandidateViewModal.vue'
import RecentlyViewedButton from '@/components/Candidate/Overview/RecentlyViewedButton.vue'
import { setRecentViewed } from '@/utils/recent-viewed-candidates'
import AddToProjectButton from '@/components/Project/AddToProjectButton.vue'
import RemoveModal from '@/components/Modals/RemoveModal.vue'

interface IProps {
  candidateData: Object
  projects: any[]
  removingCandidateId: string
}

const emit = defineEmits([
  'shortlistError',
  'addToProjectSuccess',
  'addToProjectError',
  'projectCreated',
  'projectCreationError',
  'shortlisted',
  'remove'
])
const props = defineProps<IProps>()

const displayCandidate = ref(true)
const showCandidateModal = ref(false)
const removeSearchModal = ref(false)

const toggleRemoveSearchModal = () => {
  removeSearchModal.value = !removeSearchModal.value
}

const closeRemoveSearchModal = () => {
  removeSearchModal.value = false
}

const toggleCandidateModal = () => {
  setRecentViewed(props.candidateData.original.profile_id)
  showCandidateModal.value = !showCandidateModal.value
  document.body.classList.toggle('overflow-hidden', showCandidateModal.value)
}
</script>
