<template>
  <div class="filter-overlay">
    <div
      class="backdrop"
      :class="isOpen ? 'block lg:hidden' : 'opacity-0 hidden'"
      @click="close"
    ></div>
    <div class="filter" :class="[{ 'is-open filter-shadow': isOpen }, $attrs.class]">
      <div class="filter__header">
        <div class="filter__title">
          <Icon name="svg-filter" class="hidden lg:block w-5 h-5 me-1" />
          Filters
        </div>
        <div class="hidden lg:flex ms-auto">
          <Button variant="primary" @click="applyFilters"> Apply Filters</Button>
        </div>
        <div
          class="max-md:absolute max-md:right-0 self-center ms-auto p-2.5 cursor-pointer lg:hidden"
          @click="close"
        >
          <Icon name="input-close" class="bg-grey-75 text-secondary-300 rounded-full size-6 p-1" />
        </div>
      </div>

      <form @submit.prevent="applyFilters">
        <div class="filter__body">
          <FilterDropdown
            label="Job Title"
            :options="titleOptions"
            :values="title"
            @select="(val) => (title = val)"
            @clear="(val) => (title = val)"
          />
          <LocationFilterDropDown
            label="Location"
            :options="locationOptions"
            :values="locations"
            :show-radius="false"
            @select="(val) => (locations = val)"
            @clear="(val) => (locations = val)"
          />

          <FilterDropdown
            label="Industry"
            :options="industriesOptions"
            :values="industries"
            @select="(val) => (industries = val)"
            @clear="(val) => (industries = val)"
          />
          <FilterDropdown
            label="Headcount"
            :options="companySizeOptions"
            :values="headCount"
            @select="(val) => (headCount = val)"
            @clear="(val) => (headCount = val)"
          />
          <FilterDropdown
            label="Employer Name"
            :options="employerOptions"
            :values="employerName"
            @select="(val) => (employerName = val)"
            @clear="(val) => (employerName = val)"
          />
          <FilterNumber
            label="Min Years in Current Role"
            :value="minYearsInCurrentRole"
            @change="(val) => (minYearsInCurrentRole = val)"
            @clear="(val) => (minYearsInCurrentRole = undefined)"
          />
          <FilterNumber
            label="Min Years in Career"
            :value="minYearsInCareer"
            @change="(val) => (minYearsInCareer = val)"
            @clear="(val) => (minYearsInCareer = undefined)"
          />
        </div>
        <div class="filter__footer">
          <Button type="button" variant="primary" @click="applyFilters"> Apply Filters</Button>

          <Button
            type="reset"
            variant="outline-secondary"
            class="text-secondary-600"
            @click="resetFilters"
          >
            <div class="flex justify-center">
              <Icon name="svg-trash" class="hidden lg:block w-5 h-5 me-2" />
              Clear Filters
            </div>
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import Button from '@/components/Buttons/Button.vue'
import FilterDropdown from '@/components/Filters/FilterDropdown.vue'
import FilterNumber from '@/components/Filters/FilterNumber.vue'
import LocationFilterDropDown from '@/components/Filters/LocationFilterDropDown.vue'

import { useSearchStore } from '@/stores/search'

interface IProps {
  open?: boolean
  table?: any
  locationOptions?: any
}

defineOptions({
  inheritAttrs: false
})

const headCountOptions = [
  { label: '1-10', value: 1 },
  { label: '10-50', value: 10 },
  { label: '50-200', value: 50 },
  { label: '200-500', value: 200 },
  { label: '500-1,000', value: 500 },
  { label: '1,000-5,000', value: 1000 },
  { label: '10,000+', value: 10000 }
]

const props = withDefaults(defineProps<IProps>(), {
  open: false,
  locationOptions: []
})
const emit = defineEmits(['close', 'apply'])

const isOpen = ref<boolean>(false)
const jobTitleColumn = props.table.getColumn('title')
const locationColumn = props.table.getColumn('locations')
const industriesColumn = props.table.getColumn('industry')
const employerNameColumn = props.table.getColumn('company_name')
const companySizeColumn = props.table.getColumn('company_size')
const minYearsInCurrentRoleColumn = props.table.getColumn('total_experience_in_position')
const minYearsInCareerColumn = props.table.getColumn('total_career_experience')
const locations = ref([])

const industriesOptions = Array.from(industriesColumn.getFacetedUniqueValues().keys())
  .filter((val) => val !== '' && val !== null)
  .sort()
const titleOptions = Array.from(jobTitleColumn.getFacetedUniqueValues().keys())
  .filter((val) => val !== '' && val !== null)
  .sort()
  .slice(0, 5000)
const employerOptions = Array.from(employerNameColumn.getFacetedUniqueValues().keys())
  .filter((val) => val !== '' && val !== null)
  .sort()

const companySizeOptionsFromTable = Array.from(companySizeColumn.getFacetedUniqueValues().keys())
const companySizeOptions = headCountOptions.filter((size) =>
  companySizeOptionsFromTable.includes(size.value)
)

const title = ref<string[]>([])

const industries = ref<string[]>([])
const headCount = ref<{ label: string; value: number; type: string }[]>([])
const employerName = ref<string[]>([])

const minYearsInCurrentRole = ref<number | undefined>(undefined)
const minYearsInCareer = ref<number | undefined>(undefined)

const applyFilters = () => {
  jobTitleColumn.setFilterValue(title.value)
  locationColumn.setFilterValue(locations.value)
  industriesColumn.setFilterValue(industries.value)
  companySizeColumn.setFilterValue(headCount.value)
  employerNameColumn.setFilterValue(employerName.value)
  minYearsInCurrentRoleColumn.setFilterValue(
    minYearsInCurrentRole.value ? minYearsInCurrentRole.value * 12 : undefined
  )
  minYearsInCareerColumn.setFilterValue(
    minYearsInCareer.value ? minYearsInCareer.value * 12 : undefined
  )
  props.table.resetSorting()
}

const resetFilters = () => {
  title.value = []
  locations.value = []
  industries.value = []
  headCount.value = []
  employerName.value = []
  minYearsInCurrentRole.value = undefined
  minYearsInCareer.value = undefined

  props.table.resetColumnFilters()
}

const close = () => {
  isOpen.value = false
  emit('close')
}

watch(
  () => props.open,
  (openStatus) => {
    if (openStatus) {
      document.body.classList.add('max-lg:overflow-hidden')
    } else {
      document.body.classList.remove('max-lg:overflow-hidden')
    }
    isOpen.value = openStatus
  }
)
</script>

<style>
@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.filter {
  @apply hidden lg:block w-full px-4 md:px-8 lg:px-4 bg-secondary-50 overscroll-contain lg:animate-none;
  animation: slideFromBottom 0.5s ease-out forwards;

  .filter__header {
    @apply flex py-2.5 justify-center md:justify-between items-center md:border-b border-secondary-100 relative;

    .filter__title {
      @apply flex text-lg leading-6 lg:text-xl text-center md:text-start font-bold lg:font-semibold text-grey-900;
    }
  }

  .filter__body {
    @apply mt-4 lg:mt-6 lg:px-2 grid gap-3;

    .filter__section {
      @apply border-b last:border-b-0 pb-3 last:pb-0;

      .filter__section__name {
        @apply flex justify-between text-sm font-medium;
      }

      .filter__section__label {
        @apply text-secondary-900;
      }

      .filter__section__clear {
        @apply text-secondary-400 underline hover:no-underline ms-auto;
      }
    }
  }

  .filter__footer {
    @apply grid gap-4 mt-10;
  }
}

.filter.is-open {
  @apply z-[2147483600] block md:max-h-[100vh] md:h-[calc(100%-80px)] lg:max-h-none max-md:rounded-t-20 max-lg:fixed inset-0 md:left-auto py-5 md:content-center md:overflow-y-auto;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .filter {
    animation: slideFromRight 0.5s ease-out forwards;
  }
  .filter-shadow.is-open {
    box-shadow:
      0px 47px 47px 0px rgba(117, 117, 117, 0.09),
      0px 12px 26px 0px rgba(117, 117, 117, 0.1);
  }
}

.backdrop {
  @apply z-[2147483600] transition-opacity ease-linear duration-150 w-screen h-screen fixed inset-0 bg-secondary-950/50 md:bg-transparent;
}
</style>
