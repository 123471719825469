<template>
  <div class="flex gap-3 w-60 relative">
    <Slider
      class="w-80 self-center slider-blue"
      v-model="localValue"
      :tooltips="false"
      :disabled="disabled"
    />
    <span class="text-gray-400 font-medium text-xs absolute left-0 bottom-[-8px]">0</span>
    <span class="text-gray-400 font-medium text-xs absolute right-[70px] bottom-[-8px]">100</span>

    <input
      type="number"
      min="0"
      max="100"
      v-model="localValue"
      :disabled="disabled"
      class="w-14 h-7 rounded p-1 border border-gray-200 font-medium text-sm"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import Slider from '@vueform/slider'

interface Props {
  value: number
  disabled?: boolean
}

const emit = defineEmits(['change'])
const props = defineProps<Props>()

const localValue = ref(props.value)

watch(
  () => props.value,
  () => {
    localValue.value = props.value
  }
)

watch(localValue, () => {
  emit('change', localValue.value)
})
</script>

<style src="@vueform/slider/themes/tailwind.css"></style>

<style>
.slider-connect {
  @apply absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-primary-500 cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-gray-400 disabled:cursor-not-allowed;
}

.slider-handle {
  @apply absolute rounded-full  bg-white border-4 border-primary-500  shadow-slider cursor-grab focus:outline-none h:w-4 h:h-4 h:-top-1.5 h:-right-2 txt-rtl-h:-left-2 txt-rtl-h:right-auto v:w-4 v:h-4 v:-top-2 v:-right-1.25 disabled:cursor-not-allowed focus:ring focus:ring-primary-500 focus:ring-opacity-30;
}

[disabled] .slider-handle {
  @apply border-gray-400;
}

.slider-base {
  @apply bg-gray-200;
}
</style>
