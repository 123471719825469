<template>
  <div
    class="bg-white md:shadow-lg max-lg:w-full overflow-y-scroll"
    :class="{ 'lg:w-[80%] 2xl:w-[53%]': !compared }"
  >
    <SectionsLoader v-if="!candidateData" />
    <div v-else>
      <div
        class="lg:hidden bg-white fixed flex flex-row px-4 md:px-10 py-2 z-50 w-full justify-end border-b border-b-transparent md:border-b-secondary-100"
      >
        <IconButton
          icon="input-close"
          class="border-none"
          icon-class="size-10 p-2"
          @onClick="$emit('close')"
        />
      </div>
      <div class="pt-14 lg:pt-0">
        <div
          class="py-6 md:py-4 md:mt-2 lg:mt-0 px-4 md:px-8 lg:px-6 border-b md:border-b-secondary-100 border-b-transparent"
        >
          <div class="flex flex-row w-full justify-between">
            <div class="flex flex-col gap-3 max-lg:w-full">
              <div class="flex flex-row gap-2 items-center justify-between">
                <div class="flex gap-1 lg:gap-5 items-center">
                  <a
                    :href="`https://www.linkedin.com/in/${candidateData.profileId}`"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="svg-linkedin" class="size-6 md:size-8" />
                  </a>
                  <span
                    class="text-2xl md:text-3xl font-semibold text-grey-900 lg:!leading-[44px]"
                    >{{ candidateData.name }}</span
                  >
                </div>
                <Button
                  variant="outline-primary"
                  class="flex gap-2 border-none hover:bg-transparent hover:underline !px-1 text-nowrap"
                  @click="viewCandidate"
                >
                  Open in new tab <Icon name="svg-external-link" class="size-5" />
                </Button>
              </div>
              <div class="flex flex-row flex-wrap gap-2 items-center">
                <div class="">
                  <span class="text-secondary-900 text-md">
                    {{candidateData.workExperience[0].title}}
                  </span>
                </div>
                <div class="flex gap-1 items-center">
                  <div
                    v-if="location"
                    class="flex bg-burgundy-50 rounded-lg text-burgundy-800 p-1 px-2 items-center gap-1 lg:max-h-[27px]"
                  >
                    <Icon name="svg-location" class="size-4 stroke-burgundy-800 text-burgundy-800" />
                    <span class="text-sm flex-wrap leading-5"> {{location}}</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-row flex-wrap gap-2 items-center">
                <div class="me-2">
                  <span class="text-secondary-700 text-sm">
                    {{ candidateData.workExperience[0].company_name}}
                  </span>
                </div>
                <div class="flex gap-1 items-center">
                  <Icon name="input-time" class="size-4 md:size-4 text-secondary-700" />
                  <span class="text-secondary-700 text-sm">
                    Role:
                    {{
                      convertMonthsToYearsMonths(
                        candidateData.workExperience[0].total_experience_in_position
                      )
                    }}
                  </span>
                </div>
                <div class="flex gap-1 items-center">
                  <Icon name="input-time" class="size-4 md:size-4 text-secondary-700" />
                  <span class="text-secondary-700 text-sm">
                    Company:
                    {{
                      convertMonthsToYearsMonths(
                        candidateData.workExperience[0].total_time_in_company
                      )
                    }}
                  </span>
                </div>
                <div class="flex gap-1 items-center">
                  <Icon name="input-time" class="size-4 md:size-4 text-secondary-700" />
                  <span class="text-secondary-700 text-sm">
                    Career: {{ convertMonthsToYearsMonths(candidateData.careerExp) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="hidden lg:flex items-center">
              <IconButton
                icon="input-close"
                icon-class="size-6 text-secondary-400"
                class="border-none"
                @onClick="$emit('close')"
              />
            </div>
          </div>

          <div class="hidden md:flex lg:hidden flex-row md:mt-6">
            <div class="flex flex-row w-full items-center justify-between gap-6">
              <div class="flex gap-2">
                <ShortlistButton
                  v-if="!isProjectPage"
                  :search-id="route.params.searchId"
                  :search-result-id="searchResultId"
                  @error="handleShortlistError"
                />
                <ShortlistButtonForProject
                  v-if="isProjectPage"
                  :candidate-id="projectCandidateId"
                  @shortlisted="$emit('shortlisted', $event)"
                  @error="handleShortlistError"
                />

                <Button variant="outline-secondary">
                  <RouterLink :to="`/?tab=linkedin&liId=${candidateData.profileId}`" target="_blank"
                    >Use as Archetype</RouterLink
                  >
                </Button>
                <AddToProjectButton
                  class="self-center"
                  :projects="projects"
                  :search-result-id="searchResultId"
                  @addToProjectSuccess="handleAddToProjectSuccess"
                  @addToProjectError="handleAddToProjectError"
                  @projectCreated="handleProjectCreated"
                  @projectCreationError="handleProjectCreationError"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:relative max-lg:w-full">
        <CandidateTabs
          :candidate-data="candidateData"
          :archetype-data="archetypeData"
          :candidate-id="candidateId"
          :search-result-id="searchResultId"
          :display-compare-title="true"
          @compare="(value) => (compared = value)"
        >
          <template #actions>
            <div class="max-sm:hidden flex flex-row w-full items-center justify-between gap-3">
              <div class="ml-1">
                <RecentlyViewedButton :candidate-id="candidateData.profileId" />
              </div>
              <div class="max-lg:hidden flex gap-2 items-center">
                <AddToProjectButton
                  :projects="projects"
                  :search-result-id="searchResultId"
                  @addToProjectSuccess="handleAddToProjectSuccess"
                  @addToProjectError="handleAddToProjectError"
                  @projectCreated="handleProjectCreated"
                  @projectCreationError="handleProjectCreationError"
                />
                <Button variant="outline-secondary" class="!px-3">
                  <RouterLink :to="`/?tab=linkedin&liId=${candidateData.profileId}`" target="_blank"
                    >Use as Archetype</RouterLink
                  >
                </Button>
                <ShortlistButton
                  v-if="!isProjectPage"
                  :search-id="route.params.searchId"
                  :search-result-id="searchResultId"
                  @error="handleShortlistError"
                />
                <ShortlistButtonForProject
                  v-if="isProjectPage"
                  :candidate-id="projectCandidateId"
                  @shortlisted="$emit('shortlisted', $event)"
                  @error="handleShortlistError"
                />
              </div>
            </div>
          </template>
        </CandidateTabs>
        <div
          class="max-sm:flex flex-col hidden gap-4 p-4 max-md:px-0 max-md:mx-4 rounded-t-xl border-secondary-400 button-card-shadow relative"
        >
          <div class="flex flex-row text-center">
            <AddToProjectButton
              class="w-1/4"
              position-classes="bottom-10 max-md:inset-x-0"
              :projects="projects"
              :search-result-id="searchResultId"
              @addToProjectSuccess="handleAddToProjectSuccess"
              @addToProjectError="handleAddToProjectError"
              @projectCreated="handleProjectCreated"
              @projectCreationError="handleProjectCreationError"
            />

            <Button variant="outline-secondary" class="w-3/4">
              <RouterLink :to="`/?tab=linkedin&liId=${candidateData.profileId}`" target="_blank"
                >Use as Archetype</RouterLink
              >
            </Button>
          </div>
          <div class="flex flex-row w-full">
            <ShortlistButton
              v-if="!isProjectPage"
              class="w-full"
              :search-id="route.params.searchId"
              :search-result-id="searchResultId"
              @error="handleShortlistError"
            />
            <ShortlistButtonForProject
              v-if="isProjectPage"
              class="w-full"
              :candidate-id="projectCandidateId"
              @error="handleShortlistError"
              @shortlisted="$emit('shortlisted', $event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full">
      <Message
        v-if="shortlistError"
        class="fixed bottom-0 custom-width-for-message"
        :timeout="3000"
        type="error"
        :message="shortlistErrorMessage"
        @timeout="shortlistError = false"
      />
      <Message
        v-if="error"
        class="fixed bottom-0 custom-width-for-message"
        :timeout="3000"
        type="error"
        :message="errorMessage"
        @timeout="error = false"
      />
      <Message
        v-if="success"
        class="fixed bottom-0 custom-width-for-message"
        :timeout="3000"
        type="success"
        :message="successMessage"
        @timeout="success = false"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import Button from '@/components/Buttons/Button.vue'
import IconButton from '@/components/Buttons/IconButton.vue'
import ShortlistButton from '@/components/Shortlist/ShortlistButton.vue'
import { default as ShortlistButtonForProject } from '@/views/Projects/ProjectsCandidatesPage/Components/ShortListButton.vue'
import Icon from '@/components/Icon/Icon.vue'
import { computed, defineProps, ref } from 'vue'
import CandidateTabs from '@/components/Modals/Candidate/components/CandidateTabs.vue'
import RecentlyViewedButton from '@/components/Candidate/Overview/RecentlyViewedButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { convertMonthsToYearsMonths } from '@/utils'
import AddToProjectButton from '@/components/Project/AddToProjectButton.vue'
import SectionsLoader from '@/components/Loaders/SectionsLoader.vue'
import Message from '@/components/Message/Message.vue'

interface IProps {
  candidateData: object
  archetypeData?: object
  candidateId: number
  searchResultId: number
  shortlisted: boolean
  projects: any[]
  isProjectPage: boolean
  projectCandidateId: string
}

const emit = defineEmits(['addToProjectSuccess', 'projectCreated', 'shortlisted'])
const props = defineProps<IProps>()

const route = useRoute()
const router = useRouter()

const compared = ref(false)
const shortlistError = ref(false)
const shortlistErrorMessage = ref('')
const error = ref(false)
const errorMessage = ref('')
const success = ref(false)
const successMessage = ref('')


const location = computed(() =>
  [props.candidateData?.city, props.candidateData?.state, props.candidateData?.country]
    .filter((x) => x)
    .join(', ')
)

const viewCandidate = () => {
  if (!props.isProjectPage) {
    window.open(
      router.resolve({
        name: 'candidate',
        params: {
          searchId: route.params.searchId,
          searchResultId: props.searchResultId
        }
      }).href,
      '_blank'
    )
  } else {
    window.open(
      router.resolve({
        name: 'projectCandidate',
        params: {
          projectId: route.params.projectId,
          searchResultId: props.searchResultId
        }
      }).href,
      '_blank'
    )
  }
}

const handleShortlistError = (error: Error) => {
  shortlistError.value = true
  shortlistErrorMessage.value = error.message
}

const handleAddToProjectError = (err: string) => {
  error.value = true
  errorMessage.value = err
}

const handleAddToProjectSuccess = () => {
  success.value = true
  successMessage.value = 'Candidate added to project successfully'
  emit('addToProjectSuccess')
}

const handleProjectCreated = () => {
  success.value = true
  successMessage.value = 'Project created successfully'
  emit('projectCreated')
}

const handleProjectCreationError = (err: string) => {
  error.value = true
  errorMessage.value = err
}
</script>

<style scoped>
.custom-width-for-message {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}
</style>
