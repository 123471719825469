<template>
  <div
    class="max-lg:block max-lg:relative max-lg:z-50 lg:inline-flex gap-2 lg:gap-4 xl:gap-6 items-center bg-white max-lg:pb-5"
  >
    <DropdownMenuItem
      label="New Search"
      :active="useRoute().name == 'homePage'"
      :items="newSearchMenuItem"
      position="absolute max-lg:relative lg:top-14 lg:mt-5 lg:rounded-t-none lg:menu-popup-shadow"
    />
    <DropdownMenuItem
      label="Searches & Shortlists"
      class="max-lg:mt-4"
      :active="useRoute().name == 'savedSearches' || useRoute().name == 'recentSearches'"
      :items="searchesAndShortlistsMenuItem"
      position="absolute max-lg:relative lg:top-14 lg:mt-5 lg:rounded-t-none lg:menu-popup-shadow"
    />
    <Link
      exact-active-class="!text-primary bg-primary-50"
      variant="clear"
      to="/projects"
      class="max-lg:ml-5 max-lg:mt-4 max-lg:block font-semibold p-3 border border-transparent hover:border hover:rounded-lg hover:border-primary hover:bg-secondary-50 text-secondary-900"
    >
      Projects
    </Link>
    <div class="hidden ml-7 my-2 max-md:flex">
      <UserDropdown />
    </div>
    <div class="flex md:hidden justify-center items-center py-2">
      <svg
        width="358"
        height="1"
        viewBox="0 0 358 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="0.5" x2="358" y2="0.5" stroke="#EDEEF1" />
      </svg>
    </div>
    <Link
      variant="clear"
      to="/"
      class="md:hidden ml-5 block font-semibold p-3 hover:border hover:rounded-lg hover:border-primary hover:bg-secondary-50 text-secondary-900"
      @click="logout"
    >
      Log Out
    </Link>
    <Button
      variant="outline-secondary"
      class="hidden max-lg:ml-7 max-lg:my-2 max-lg:flex text-nowrap border-secondary-900"
      @click="$emit('sendInviteClick')"
    >
      <Icon name="user-like" class="stroke-2 text-secondary-500 me-2 w-5 h-5" />
      Invite People
    </Button>
  </div>
</template>

<script setup lang="ts">
import Link from '@/components/Link/Link.vue'
import DropdownMenuItem from '@/components/MainHeader/DropdownMenuItem.vue'
import type { DropdownItem } from '@/components/Dropdown/dropdown'
import { useRoute, useRouter } from 'vue-router'
import Button from '@/components/Buttons/Button.vue'
import UserDropdown from '@/components/MainHeader/UserDropdown.vue'
import Icon from '@/components/Icon/Icon.vue'
import { useUserStore } from '@/stores/user'

const newSearchMenuItem: DropdownItem[] = [
  {
    label: 'Job Title Search',
    description:
      'Create an ideal personalized Archetype for maximum search flexibility. Select current role and experience, industries or even specific companies. Add past experience roles for laser-focused results.',
    icon: 'svg-portfolio',
    route: '/?tab=job-title'
  },
  {
    label: 'LinkedIn Profile URL',
    description:
      'Lookalikes starts by identifying a person that should be used as the Archetype. Our AI will deliver the best candidates matching your Archetype out of a pool of 1B+ people.',
    icon: 'auth-linkedin',
    route: '/?tab=linkedin'
  }
]

const searchesAndShortlistsMenuItem: DropdownItem[] = [
  {
    label: 'Saved',
    description: "Save important searches and candidates so you don't lose them",
    icon: 'svg-save',
    route: { name: 'savedSearches' }
  },
  {
    label: 'Recent',
    description: 'All recently viewed searches within the last 10 days will be saved here',
    icon: 'svg-reset',
    route: { name: 'recentSearches' }
  }
]

const router = useRouter()
const store = useUserStore()

const logout = async () => {
  await store.logout()
}
</script>

<style>
.menu-popup-shadow {
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
</style>
