<template>
  <SectionBlock
    :title="title"
    description="Enter or paste keywords, up to 3 words within each keyword."
  >
    <div class="flex flex-col gap-2">
      <div v-for="(row, index) in rows" :key="index" class="flex flex-col bg-white rounded-lg p-4">
        <div class="flex flex-col md:flex-row bg-white rounded-lg gap-4">
          <KeywordsInput
            class="w-full md:flex-1 order-2 md:order-1"
            placeholder="Start typing a keyword..."
            :selectedValues="row.keywords"
            :selectedOptions="row.keywords"
            @change="(e) => handleKeywordsChange(index, e)"
          />
          <TwoStateButton
            class="order-1 md:order-2 self-start w-full md:w-auto"
            leftText="Required"
            rightText="Desired"
            :leftSelected="!row.desired"
            @left="handleLeft(index)"
            @right="handleRight(index)"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-between mt-4">
      <p class="text-danger-500 text-sm mb-3 md:mb-0">
        Note: Applying this filter may significantly reduce the number of results.
      </p>
      <Button variant="outline-primary" type="button" class="max-md:w-full" @click="addRow">
        Add Row
      </Button>
    </div>
  </SectionBlock>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import KeywordsInput from '@/components/Inputs/KeywordsInput.vue'
import SectionBlock from '@/components/Tabs/SectionBlock.vue'
import TwoStateButton from '@/components/TwoStateButton.vue'
import Button from '@/components/Buttons/Button.vue'

interface IProps {
  title: string
  keywords: { desired: boolean; keywords: string[] }[]
}

const emit = defineEmits(['change'])
const props = defineProps<IProps>()

const rows = ref(
  props.keywords || [
    { desired: true, keywords: [] },
    { desired: false, keywords: [] }
  ]
)

watch(
  () => props.keywords,
  () => {
    emit('change', rows.value)
  },
  { immediate: true }
)

const addRow = () => {
  if (rows.value.length < 10) {
    rows.value.push({ desired: true, keywords: [] })
  }
}

const handleLeft = (index: number) => {
  rows.value[index].desired = false
  emit('change', rows.value)
}

const handleRight = (index: number) => {
  rows.value[index].desired = true
  emit('change', rows.value)
}

const handleKeywordsChange = (index: number, keywords: string[]) => {
  rows.value[index].keywords = keywords
  emit('change', rows.value)
}
</script>
