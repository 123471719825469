<template>
  <component :is="layout"></component>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout.vue'
import NoLayout from '@/layouts/NoLayout.vue'

import AuthLayout from '@/layouts/AuthLayout.vue'
import { useUserStore } from '@/stores/user'
import Intercom from '@intercom/messenger-js-sdk'

const userStore = useUserStore()

watch(
  () => userStore.user,
  () => {
    Intercom({
      app_id: 'a7jop0fs',
      user_id: userStore.user?.id,
      email: userStore.user?.email,
      name: userStore.getUserName,
      created_at: Math.floor(new Date(userStore.user?.created_at).getTime() / 1000)
    })
  }
)

const route = useRoute()
const layout = computed(() => {
  if (route.meta.layout === 'default') {
    return DefaultLayout
  }
  // if (route.meta.layout === 'auth') {
  //   return NewAuthLayout
  // }

  return NoLayout
})
</script>
