<template>
  <div class="bg-gray-100 rounded-lg p-1 flex gap-2">
    <button
      type="button"
      @click="handleLeft"
      :class="{ 'selected-button': isLeftSelected }"
      class="px-4 py-1 w-1/2"
    >
      {{ leftText }}
    </button>
    <button
      type="button"
      @click="handleRight"
      :class="{ 'selected-button': !isLeftSelected }"
      class="px-4 py-1 w-1/2"
    >
      {{ rightText }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, ref } from 'vue'

interface IProps {
  leftText: string
  rightText: string
  leftSelected?: boolean
}

const props = defineProps<IProps>()

const emit = defineEmits(['left', 'right'])

const isLeftSelected = ref(props.leftSelected ? true : false)

const handleLeft = () => {
  isLeftSelected.value = true
  emit('left')
}

const handleRight = () => {
  isLeftSelected.value = false
  emit('right')
}
</script>

<style scoped>
.selected-button {
  @apply bg-purple-700 text-white rounded-lg;
  transition:
    background-color 0.8s ease,
    color 0.5s ease;
}
</style>
