<template>
  <Dropdown
    :label="label"
    :items="items"
    :disabled="disabled"
    :active="active"
    @select="followLink"
    :position="position"
  >
    <template #button="{ isOpen, toggle, active, disabled }">
      <button
        class="max-lg:ml-5 inline-flex cursor-pointer items-center p-2 lg:p-3 text-base font-semibold rounded-lg border border-transparent lg:hover:border lg:hover:border-primary hover:bg-secondary-50 active:text-primary active:bg-primary-50 disabled:border-0 disabled:bg-secondary-50 disabled:text-secondary-300 focus:outline-none"
        :class="[
          active || isOpen ? 'text-primary bg-primary-50' : 'text-secondary-900 bg-secondary-50'
        ]"
        :disabled="disabled"
        @click="toggle"
      >
        {{ label }}
        <Icon :name="isOpen ? 'input-arrow-up' : 'input-arrow-down'" class="w-4 h-4 ms-2" />
      </button>
    </template>
    <template #items="{selectItem}">
      <div class="max-lg:py-0 max-lg:px-7 p-4 md:flex sm:relative">
        <div
          v-for="item in items"
          :key="item.label"
          @click="selectItem(item)"
          class="flex p-3 w-96 cursor-pointer hover:bg-secondary-100 hover:rounded-lg"
        >
          <div class="max-md:hidden w-10 h-10 content-center border rounded-full border-secondary-100 me-3">
            <Icon :name="item.icon" class="h-5 w-5 mx-2.5 text-primary m-auto" />
          </div>
          <div>
            <Link v-if="item.route" :to="item.route" class="font-semibold text-secondary-900 max-lg:text-secondary-600">{{
              item.label
            }}</Link>
            <span v-else class="text-base font-semibold text-secondary-900 max-lg:text-secondary-600">{{ item.label }}</span>
            <p class="max-md:hidden text-sm font-medium text-secondary-600">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'
import Dropdown from '@/components/Dropdown/Dropdown.vue'
import type { DropdownItem, DropdownProps } from '@/components/Dropdown/dropdown'
import { useRouter } from 'vue-router'
import Link from '@/components/Link/Link.vue'

const router = useRouter()

const props = withDefaults(defineProps<DropdownProps>(), {
  active: false,
  disabled: false,
  closeOnSelect: false,
  position: 'absolute max-lg:relative'
})

const followLink = (item: DropdownItem) => {
  if (item.route) {
    router.push(item.route)
  }
}
</script>
