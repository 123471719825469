<template>
  <div class="flex flex-col items-start p-0 w-72 self-start" v-click-away="close">
    <label
      :for="name || `form-input-${$.uid}`"
      :class="[{ 'text-secondary-300': disabled, 'text-secondary-600': !disabled }, labelClass]"
      class="text-sm font-semibold"
    >
      {{ label }}
    </label>

    <Multiselect
      ref="multiselectElement"
      :id="name || `form-input-${$.uid}`"
      :mode="mode"
      :name="name"
      :options="options"
      :create-option="true"
      :add-option-on="['enter', 'tab', ',']"
      :searchable="true"
      :required="required"
      :show-options="false"
      :can-clear="true"
      :disabled="disabled"
      :placeholder="!disabled ? placeholder : ''"
      v-model="value"
      autocomplete="hack-to-turn-of-autocomplete-in-chrome"
      :clear-on-blur="false"
      :classes="{
        container:
          'multiselect-custom relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-500 rounded-lg bg-white text-base leading-snug outline-none group hover:bg-secondary-100 ' +
          inputClass,
        wrapper:
          'relative mx-auto w-full flex items-center justify-between box-border cursor-pointer outline-none p-1',
        options: 'flex flex-col items-start px-2 py-0.5 gap-1.5 overflow-y-scroll',
        option: 'flex justify-between items-center p-2 px-3 w-full',
        optionSelected: 'bg-primary-100 border border-primary-400 rounded-lg',
        optionSelectedPointed:
          'bg-primary-100 border border-primary-400 rounded-lg hover:bg-primary-200',
        optionPointed: 'text-gray-800 bg-gray-150 rounded-lg hover:bg-secondary-100',
        tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 px-2 min-w-0 rtl:pl-0 rtl:pr-2 overflow-hidden gap-2',
        tag: 'flex flex-row items-center p-0 px-2 bg-primary-50 rounded font-medium text-sm text-primary-700 pr-1',
        dropdown:
          'max-h-60 absolute -left-px -right-px transform bottom-[-5px] translate-y-full border border-gray-500 rounded-lg mt-2 z-50 bg-white flex flex-col overflow-hidden',
        dropdownHidden: 'hidden',
        containerDisabled: 'border-secondary-200',
        tagsSearch:
          'absolute inset-0 border-0 outline-none focus:ring-0 focus:appearance-none p-0 text-sm leading-6 font-sans box-border w-full group-hover:bg-secondary-100',
        search:
          'inset-0 border-0 outline-none focus:ring-0 focus:appearance-none px-3 py-1 text-sm leading-6 rounded-lg w-full group-hover:bg-secondary-100',
        fakeInput:
          'bg-transparent border-0 h-px text-[0] absolute -bottom-px left-0 !ring-transparent ring-offset-transparent w-full !outline-none !shadow-none p-0',
        // singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
        clear:
          'pr-3.5 relative z-10 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
        spinner: 'hidden'
      }"
      @change="change"
      :on-create="handleTagCreate"
      @close="close"
    />

    <p v-if="tagError" class="text-red-500 text-sm block">{{ tagError }}</p>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from 'vue'
import Multiselect from '@vueform/multiselect'

interface IProps {
  mode?: string
  label?: string
  labelClass?: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  name?: string | number
  isValid?: boolean
  error?: string | string[]
  clearable?: boolean
  selectedOptions?: string[]
  selectedValues?: string | null | { label: string; value: string } | string[]
  inputClass?: string
}

const props = withDefaults(defineProps<IProps>(), {
  clearable: false,
  searchable: true,
  mode: 'tags',
  placeholder: 'Type here',
  selectedOptions: null,
  selectedValues: null
})

const emit = defineEmits(['change'])

const multiselectElement = ref(null)
const value = ref(props.selectedValues ?? null)
const tagError = ref<string | null>(null)
const options = ref(props.selectedOptions ?? [])

const change = (data) => {
  emit('change', data)
}

const handleTagCreate = (data) => {
  const tagValue = typeof data === 'string' ? { value: data, label: data } : data
  if ((tagValue.value.match(/,/g) || []).length > 1) {
    const words = tagValue.value.split(',')
    tagError.value = 'Some keywords have more than 3 words:'
    let isError = false
    for (const word of words) {
      if (word.split(' ').length > 3) {
        tagError.value += ` ${word}`
        isError = true
      }
    }
    if (isError) {
      // value.value = tagValue
      return false
    }
    tagError.value = null
    return words.map((word: string) => {
      return {
        label: word,
        value: word
      }
    })
  }
  // check if the value has more than 2 white spaces
  if ((tagValue.value.match(/\s/g) || []).length <= 2) {
    return tagValue
  } else {
    console.log('Data has more than 3 white spaces')
    tagError.value = 'You have typed more than 3 words'
    return false
  }
}

const close = (e) => {
  if (multiselectElement.value.search) {
    const keyboardEvent = new KeyboardEvent('keydown', {
      code: 'Enter',
      key: 'Enter',
      charCode: 13,
      keyCode: 13,
      view: window,
      bubbles: true
    })
    multiselectElement.value.input.dispatchEvent(keyboardEvent)
  }
}

defineExpose({
  multiselectElement
})
</script>

<style scoped>
.multiselect-custom ::-webkit-scrollbar {
  width: 4px;
}

.multiselect-custom ::-webkit-scrollbar-track {
  background: #edeef1;
  border-radius: 50px;
}

.multiselect-custom ::-webkit-scrollbar-thumb {
  background: #b3bac6;
  border-radius: 24px;
}
</style>

<style src="../../../node_modules/@vueform/multiselect/themes/default.css"></style>
