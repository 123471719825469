<template>
  <div class="relative md:first:-mt-2">
    <BaseCandidateCard
      v-if="displayCandidate"
      :candidate-data="candidateData"
      @viewCandidate="toggleCandidateModal"
    >
      <template #buttons>
        <div class="flex flex-wrap flex-row md:flex-col gap-4 max-sm:mt-4 relative max-md:w-full">
          <div class="flex items-center md:gap-3">
            <RecentlyViewedButton
              last-viewed="12"
              :candidate-id="candidateData.original.profile_id"
              :display-at-first-time="true"
              tooltip-position="left"
              class="max-sm:hidden"
            />
            <Button variant="primary" class="w-full" @onClick="toggleCandidateModal">View</Button>
          </div>
          <div class="flex items-center">
            <ShortlistButton
              class="w-full"
              :search-id="searchStore.searchId"
              :candidate-data="candidateData.original"
              :search-result-id="candidateData.original.id"
              @error="$emit('shortlistError', $event)"
            />
          </div>
          <div class="flex justify-center gap-3">
            <AddToProjectButton
              :projects="projects"
              :search-result-id="candidateData.original.id"
              @addToProjectSuccess="$emit('addToProjectSuccess', true)"
              @addToProjectError="$emit('addToProjectError', $event)"
              @projectCreated="$emit('projectCreated', true)"
              @projectCreationError="$emit('projectCreationError', $event)"
            />
          </div>
          <CandidateViewModal
            v-if="showCandidateModal"
            :candidate-id="candidateData.original.candidateId"
            :search-result-id="candidateData.original.id"
            :projects="projects"
            @candidateDataError="handleCandidateDataError"
            @close="toggleCandidateModal"
            @addToProjectSuccess="$emit('addToProjectSuccess', false)"
            @projectCreated="$emit('projectCreated', false)"
          />
        </div>
      </template>
    </BaseCandidateCard>
  </div>
</template>
<script setup lang="ts">
import Button from '@/components/Buttons/Button.vue'

import { defineProps, ref } from 'vue'
import BaseCandidateCard from '@/components/Card/Candidate/BaseCandidateCard.vue'
import ShortlistButton from '@/components/Shortlist/ShortlistButton.vue'
import CandidateViewModal from '@/components/Modals/Candidate/CandidateViewModal.vue'
import RecentlyViewedButton from '@/components/Candidate/Overview/RecentlyViewedButton.vue'
import { setRecentViewed } from '@/utils/recent-viewed-candidates'
import { useSearchStore } from '@/stores/search'
import AddToProjectButton from '@/components/Project/AddToProjectButton.vue'

interface IProps {
  candidateData: Object
  projects: any[]
}

const emit = defineEmits([
  'shortlistError',
  'addToProjectSuccess',
  'addToProjectError',
  'projectCreated',
  'projectCreationError',
  'candidateDataError'
])
const props = defineProps<IProps>()
const searchStore = useSearchStore()

const displayCandidate = ref(true)
const showCandidateModal = ref(false)

const toggleCandidateModal = () => {
  setRecentViewed(props.candidateData.original.profile_id)
  showCandidateModal.value = !showCandidateModal.value
  document.body.classList.toggle('overflow-hidden', showCandidateModal.value)
}

const handleCandidateDataError = (error: any) => {
  emit('candidateDataError', error)
  toggleCandidateModal()
}
</script>
