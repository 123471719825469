<template>
  <TipCard
    :class="{ 'max-lg:hidden': !showTips }"
    class="mt-2 md:mt-4 main-tip"
    title="LinkedIn Profile URL "
    description="Lookalikes starts by identifying a person that should be used as the Archetype. Our AI will deliver the best candidates matching your Archetype out of a pool of 1B+ people."
  />

  <div class="mt-8 md:mt-6">
    <form @submit.prevent="fetchLinkedinProfile">
      <div class="flex flex-col md:flex-row">
        <div class="h-10 w-full md:me-4">
          <BasicInput
            placeholder="Linkedin URL"
            clearable
            :value="linkedinUrl"
            type="url"
            required
            :errors="linkedinUrlErrors"
            @inputChange="(value) => (linkedinUrl = value)"
          />
        </div>

        <Button
          type="submit"
          variant="primary"
          class="h-10 mt-4 md:mt-0 md:w-auto min-w-40 flex justify-center"
          :loading="fetching"
          :disabled="fetching"
          animation="dots"
        >
          <template #loading>Fetching</template>

          <div v-if="fetched" class="flex">
            <Icon name="svg-check" class="w-5 h-5 me-2" />Fetched
          </div>
          <div v-else>Fetch</div>
        </Button>
      </div>
    </form>
  </div>

  <SectionsLoader v-if="fetching" class="mt-6 md:mt-10" />

  <div v-else-if="fetched && !fetching" class="lg:border lg:rounded-2xl lg:p-6 mt-10">
    <form @submit.prevent="search">
      <CurrentPosition :selected-value="currentPosition" @jobTitleChange="currentPositionChange" />
      <div class="grid gap-6 mt-6 md:mt-8">
        <div class="lg:relative">
          <TipCard
            :class="{ 'max-lg:hidden': !showTips }"
            class="mb-4 left-side lg:-ms-6"
            title="Experience Information"
            description="Please indicate the candidate's preferred experience in current role and total experience. You can also add the candidate's previous experience."
          />
          <WorkExperience
            :role-min-years="
              route.query.prefill ? searchStore.searchPayload?.total_role_exp : roleMinYears
            "
            :career-min-years="
              route.query.prefill ? searchStore.searchPayload?.career_exp : careerMinYears
            "
            :past-roles="
              route.query.prefill ? searchStore.searchPayload?.past_experience : pastRoles
            "
            @roleMinYearsChange="(value: string) => (roleMinYears = Number(value))"
            @careerMinYearsChange="(value: string) => (careerMinYears = Number(value))"
            @pastRolesChange="(value) => (pastRoles = value)"
          />
        </div>
        <div class="lg:relative">
          <TipCard
            :class="{ 'max-lg:hidden': !showTips }"
            class="mb-4 left-side lg:-ms-6"
            title="Keyword Information"
            description="For each keyword defined, you can choose between Required or Desired. If Required, the candidate(s) must have this exact keyword contained within their career profile About blurb, career headline, current/past experience items, and education. If Desired, Lookalikes will try and find candidates with the keyword, but may still return results without all Desired keywords."
          />
          <Keywords
            title="Keywords"
            :keywords="
              route.query.prefill && searchStore.searchPayload?.keywords
                ? searchStore.searchPayload?.keywords
                : null
            "
            @change="handleKeywordsChange"
          />
        </div>
        <div class="lg:relative">
          <TipCard
            :class="{ 'max-lg:hidden': !showTips }"
            class="mb-4 left-side lg:-ms-6"
            title="Education Information"
          >
            <template #description>
              <p>
                Enter or paste education keywords, such as:
                <br />
                - Name of Institution, University or College
                <br />
                - Type of Degree (Bachelors, Masters, PHD, etc)
                <br />
                - Field of Study (Marketing, Computer Science, Finance)
              </p>
            </template>
          </TipCard>
          <Keywords
            title="Education Keywords"
            :keywords="
              route.query.prefill && searchStore.searchPayload?.education_keywords
                ? searchStore.searchPayload?.education_keywords
                : null
            "
            @change="handleEducationKeywordsChange"
          />
        </div>
        <div class="lg:relative">
          <TipCard
            :class="{ 'max-lg:hidden': !showTips }"
            class="mb-4 left-side lg:-ms-6"
            title="Employer Information"
            description="Enter the number of employees, company name, and industry. You can select several employers and industries at once."
          />
          <CandidateEmployer
            :selected-industries="
              route.query.prefill ? searchStore.searchPayload?.industries_include : null
            "
            :selected-employers="
              route.query.prefill ? searchStore.searchPayload?.companies_include : null
            "
            :selected-headcount="
              route.query.prefill ? searchStore.searchPayload?.company_size_include : null
            "
            :selected-past-employers="
              route.query.prefill ? searchStore.searchPayload?.past_employers_include : null
            "
            @headcountChange="headCountChange"
            @employerChange="employerChange"
            @industryChange="(value: string[]) => (industries = value)"
            @pastEmployerChange="pastEmployerChange"
          />
        </div>
        <div class="lg:relative">
          <TipCard
            :class="{ 'max-lg:hidden': !showTips }"
            class="mb-4 left-side lg:-ms-6"
            title="Location Information"
            description="Enter the desired location of the candidate. You can select several locations at once."
          />
          <CandidateLocation
            @locationChange="locationChange"
            @locationRadiusChange="locationRadiusChange"
            :show-error-message="locationError"
          />
        </div>
      </div>
      <div class="mt-8 text-end">
        <Button type="submit" variant="primary" class="w-40">Start Search</Button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'

import TipCard from '@/components/TipCard.vue'
import Button from '@/components/Buttons/Button.vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import SectionsLoader from '@/components/Loaders/SectionsLoader.vue'
import CurrentPosition from '@/views/Search/Components/CurrentPosition.vue'
import WorkExperience from '@/views/Search/Components/WorkExperience.vue'
import CandidateEmployer from '@/views/Search/Components/CandidateEmployer.vue'
import CandidateLocation from '@/views/Search/Components/CandidateLocation.vue'
import Keywords from '@/views/Search/Components/Keywords.vue'
import type { PastRole } from '@/stores/types'
import { useSearchStore } from '@/stores/search'
import { useBreadcrumbsStore } from '@/stores/breadcrumbs'
import { setRecentLocations } from '@/utils/recent-searches'

interface IProps {
  showTips: boolean
}

defineProps<IProps>()
const emit = defineEmits(['error', 'loading'])

const route = useRoute()
const router = useRouter()
const searchStore = useSearchStore()
const breadcrumbsStore = useBreadcrumbsStore()
const fetching = ref(false)
const fetched = ref(
  route.query.prefill && searchStore.searchPayload?.linkedin_profile_url ? true : false
)
const linkedinUrl = ref(
  route.query.prefill
    ? searchStore.searchPayload?.linkedin_profile_url
    : route.query.liId
      ? `https://www.linkedin.com/in/${route.query.liId}`
      : ''
)
const linkedinUrlErrors = ref<string[]>([])
let linkedinProfile: any = route.query.prefill ? searchStore.searchResults?.archetypeData : null
let currentPosition = ref([
  {
    label: '',
    value: ''
  }
])
const roleMinYears = ref<number | null>(null)
const careerMinYears = ref<number | null>(null)
const pastRoles = ref<PastRole[]>([])
const headCount = ref<number[]>([])
const companies = ref<string[]>([])
const pastEmployers = ref<string[]>([])
const industries = ref<string[]>([])

const locationRadius = ref<number>(25)
const locations = ref<string[]>([])
const keywords = ref([])
const educationKeywords = ref([])
const locationError = ref(false)

const handleKeywordsChange = (keywordsInput: { desired: boolean; keywords: string[] }[]) => {
  keywords.value = keywordsInput
}

const handleEducationKeywordsChange = (
  keywordsInput: { desired: boolean; keywords: string[] }[]
) => {
  educationKeywords.value = keywordsInput
}

const locationChange = (locationsValue: []) => {
  locations.value = locationsValue
}
const locationRadiusChange = (radius: number) => {
  locationRadius.value = radius
}

const employerChange = (employerOptions: { label: string; value: string }[]) => {
  companies.value = employerOptions.map((employer) => employer.value)
}

const pastEmployerChange = (pastEmployerOptions: { label: string; value: string }[]) => {
  pastEmployers.value = pastEmployerOptions.map((pastEmployer) => pastEmployer.value)
}

const calculateMonthDiff = (startDate: string, endDate: string | null) => {
  const start = new Date(startDate)
  const end = endDate ? new Date(endDate) : new Date()

  const yearsDiff = end.getFullYear() - start.getFullYear()
  const monthsDiff = end.getMonth() - start.getMonth()

  return yearsDiff * 12 + monthsDiff
}

const currentPositionChange = (positions: { label: string; value: string }[]) => {
  currentPosition.value = positions
}

const filterEmptyPastRoles = (pastRoles: PastRole[]) => {
  return pastRoles.filter((value) => value.title !== '')
}

const headCountChange = (headCounts: { label: string; value: number }[]) => {
  headCount.value = headCounts.flatMap((headCount) => {
    if (headCount.value === 1) {
      return [1, 2] // this is how daniel wants to indicate the first range, for the search query
    }
    return [headCount.value]
  })
}

const fetchLinkedinProfile = async (): Promise<void> => {
  const regex = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/

  if (!regex.test(linkedinUrl.value)) {
    linkedinUrlErrors.value = ['Invalid LinkedIn URL']
    return
  } else {
    linkedinUrlErrors.value = []
  }

  fetching.value = true

  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/getArchetypeProfile?linkedinProfileUrl=${linkedinUrl.value}`
    )

    linkedinProfile = reactive(res.data)
    currentPosition.value = [{ label: res.data.title, value: res.data.canonicalTitle }]
    pastRoles.value = res.data.archetype_experience.map((role: any) => ({
      id: Date.now(),
      title: role.title,
      canonical_title: role.canonical_title,
      minYears: calculateMonthDiff(role.starts_at, role.ends_at)
    }))
    roleMinYears.value = linkedinProfile.archetype_total_experience_in_position

    careerMinYears.value = linkedinProfile.archetype_total_career_experience

    industries.value = Array.from(
      new Set(
        res.data.archetype_experience
          .map((exp: any) => exp.industry)
          .filter((industry: string) => industry !== null)
      )
    ) // drops duplicate industries

    fetched.value = true

    searchStore.linkedinProfileData = res.data
  } catch (err) {
    if (err.response.status === 400) {
      emit(
        'error',
        'The Archetype profile you provided has insufficient experience data. Please select a profile to use'
      )
    } else {
      emit('error')
    }
  } finally {
    fetching.value = false
  }
}

const search = async (): Promise<void> => {
  if (locations.value.filter((location) => location.type === 'include').length === 0) {
    locationError.value = true
    return
  }

  locationError.value = false
  emit('loading', true)

  const searchPayload = {
    archetype_name: linkedinProfile.name,
    linkedin_profile_url: linkedinUrl.value,
    locations: locations.value,
    location_radius: locationRadius.value,
    title: currentPosition.value.map((position) => position.label),
    canonical_title: currentPosition.value.map((position) => position.value),
    title_include: currentPosition.value.map((position) => {
      return { ...position, type: 'include' }
    }),
    total_role_exp: roleMinYears.value ?? 0,
    career_exp: careerMinYears.value ?? 0,
    industries_include: industries.value,
    companies_include: companies.value,
    companies_exclude: linkedinProfile.company ? [linkedinProfile.company] : [],
    past_employers_include: pastEmployers.value,
    company_size_include: headCount.value,
    past_experience: filterEmptyPastRoles(pastRoles.value) as PastRole[],
    archetype_exp_history_from_linkedin: linkedinProfile.archetype_experience,
    archetype_cache_id: linkedinProfile.archetypeCacheId,
    keywords: keywords.value,
    education_keywords: educationKeywords.value
  }

  try {
    const res = await axios.post(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/search/linkedin-url`,
      searchPayload
    )

    searchStore.setSearchState(res.data, searchPayload)
    setRecentLocations(locations.value)

    breadcrumbsStore.setBreadcrumbs([
      {
        title: 'LinkedIn Profile Search',
        route: { name: 'homePage', query: { tab: 'linkedin' } }
      }
    ])

    router.push({ name: 'searchResults', params: { searchId: res.data.search_id } })
  } catch (err) {
    emit('error')
  } finally {
    emit('loading', false)
  }
}
</script>

<style scoped>
.main-tip {
  @apply lg:mt-0 lg:absolute lg:top-0 lg:left-0 lg:max-w-xs;
}
.left-side {
  @apply lg:absolute lg:-left-87 lg:max-w-xs;
}
</style>
