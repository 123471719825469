<template>
  <Button
    :variant="active && !loading ? 'outline-primary' : 'primary'"
    :loading="loading"
    animation="dots"
    class="flex justify-center items-center !px-2 group w-[186px] !rounded-[40px] group"
    :class="{'!bg-transparent !border-transparent predict-active': active && !loading}"
  >
    <template #loading>Predicting</template>
    <span class="contents transition-all ease-out duration-300" :class="{'group-hover:hidden group-hover:text-danger text-primary': active}">
      <Icon
        :name="active ? 'svg-check' : 'svg-stars'"
        class="size-7 p-1 me-2 text-primary rounded-full bg-white"
        :class="{'text-danger ms-2 me-0 icon-shadow order-2': active, 'animation-slide': active && showAnimation}"
      />
      Predicted Interest
    </span>
    <span class="hidden text-danger text-nowrap" :class="{'group-hover:contents': active}">
      Turn Off Prediction
      <Icon name="input-close" class="size-7 bg-white rounded-full p-1 ms-2 icon-shadow" />
    </span>
  </Button>
</template>

<script lang="ts" setup>
import { defineProps, ref, watch } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import Button from '@/components/Buttons/Button.vue'

interface IProps {
  loading?: boolean
  active?: boolean
}

const props = defineProps<IProps>()

const showAnimation = ref(true);

watch(
  () => props.active,
  (val) => {
    if (val) {
      setTimeout(
        () => {
          showAnimation.value = false;
        },
        3000
      )
    }
    else {
      showAnimation.value = true;
    }
  }
)
</script>

<style>
.predict-active {
  box-shadow: inset 0 1px 4px 0 #0000001C;
}
.icon-shadow {
  filter: drop-shadow(0 1px 3px #1018281A);
}

.animation-slide {
    -webkit-animation: slide 0.3s ease-out forwards;
    animation: slide 0.3s ease-out forwards;
}

@-webkit-keyframes slide {
  0% {
    transform: translateX(-130px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide {
  0% {
    transform: translateX(-130px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>