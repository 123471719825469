import { defineStore } from 'pinia'
import axios from 'axios'
import router from '@/router'

export const useUserStore = defineStore('user', {
  state: () => ({
    isAuthenticated: false,
    user: null,
    subscriptionStatus: null,
    subscriptionType: null,
    subscriptionEndsAt: null
  }),
  getters: {
    isLoggedIn: (state) => state.isAuthenticated,
    getUser: (state) => state.user,
    getUserName: (state) => `${state.user?.first_name ?? ''} ${state.user?.last_name ?? ''}`,
    getSubscriptionStatus: (state) => state.subscriptionStatus,
    getSubscriptionType: (state) => state.subscriptionType,
    getSubscriptionEndsAt: (state) => state.subscriptionEndsAt,
    getDaysUntilFreeTrialEnds: (state): number => {
      if (state.subscriptionType === 'trial') {
        const daysRemaining = Math.ceil(
          (new Date(state.subscriptionEndsAt).getTime() - new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        )
        return daysRemaining > 0 ? daysRemaining : 0
      }
      return 0
    }
  },
  actions: {
    login(user) {
      this.isAuthenticated = true
      this.user = user
      this.subscriptionStatus = user.subscription_status
      this.subscriptionType = user.subscription_type
      this.subscriptionEndsAt = user.subscription_ends_at
    },
    async logout() {
      await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/logout`)

      this.isAuthenticated = false
      this.user = null
      router.push({ name: 'login' })
    }
  }
})
