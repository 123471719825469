<template>
  <SubscriptionPlan
    title="Essential"
    subtitle="Per User"
    :price="566"
    pricePeriod="year"
    bgColor="bg-[#343434]"
    @upgradeNow="upgradeNow"
  >
  </SubscriptionPlan>
</template>

<script setup lang="ts">
import SubscriptionPlan from '@/views/User/Billing/Components/SubscriptionPlan.vue'

import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const emit = defineEmits(['upgradeNow'])

const daysLeftForTrial = userStore?.getDaysUntilFreeTrialEnds
const subscriptionPlanMap = {
  7: `${import.meta.env.VITE_PADDLE_ESSENTIAL_ANNUAL_7_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  6: `${import.meta.env.VITE_PADDLE_ESSENTIAL_ANNUAL_6_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  5: `${import.meta.env.VITE_PADDLE_ESSENTIAL_ANNUAL_5_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  4: `${import.meta.env.VITE_PADDLE_ESSENTIAL_ANNUAL_4_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  3: `${import.meta.env.VITE_PADDLE_ESSENTIAL_ANNUAL_3_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  2: `${import.meta.env.VITE_PADDLE_ESSENTIAL_ANNUAL_2_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  1: `${import.meta.env.VITE_PADDLE_ESSENTIAL_ANNUAL_1_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  0: `${import.meta.env.VITE_PADDLE_ESSENTIAL_ANNUAL_0_DAYS_TRIAL_SUBSCRIPTION_ID}`
}

const upgradeNow = () => {
  emit('upgradeNow', subscriptionPlanMap[daysLeftForTrial], 'essential')
}
</script>
