<template>
  <div class="filter-overlay">
    <div
      class="backdrop"
      :class="isOpen ? 'block lg:hidden' : 'opacity-0 hidden'"
      @click="close"
    ></div>
    <div class="filter" :class="[{ 'is-open filter-shadow': isOpen }, $attrs.class]">
      <div class="filter__header">
        <div class="filter__title">
          <Icon name="svg-filter" class="hidden lg:block w-5 h-5 me-1" />
          Filters
        </div>
        <div class="hidden lg:flex ms-auto">
          <Button variant="primary" @click="applyFilters"> Apply Filters</Button>
        </div>
        <div
          class="max-md:absolute max-md:right-0 self-center ms-auto p-2.5 cursor-pointer lg:hidden"
          @click="close"
        >
          <Icon name="input-close" class="bg-grey-75 text-secondary-300 rounded-full size-6 p-1" />
        </div>
      </div>

      <form @submit.prevent="applyFilters">
        <div class="filter__body">
          <FilterDropdown
            label="Job Title"
            :values="titles"
            :api-url="jobTitleAutocompleteApi"
            :delay="500"
            value-key="canonical_title"
            label-key="title"
            clearable
            param-name="title"
            save-values-as-objects
            :show-error-message="jobTitleIsEmpty"
            error-message="Please select at least one job title"
            @select="jobTitleChanged"
            @clear="(val) => (titles = val)"
          />

          <LocationFilterDropDown
            label="Location"
            :api-url="locationApi"
            :delay="500"
            param-name="location"
            :values="locations"
            :error-message="locationErrorMessage"
            :show-error-message="locationIsEmpty || locationError"
            @select="(val) => ((locations = val), (locationIsEmpty = false))"
            @clear="(val) => ((locations = val), (locationIsEmpty = false))"
            @locationRadiusChange="(val) => (locationRadius = val)"
          />

          <FilterDropdown
            label="Industry"
            :options="industriesOptions"
            :values="industries"
            @select="(val) => (industries = val)"
            @clear="(val) => (industries = val)"
          />
          <FilterKeywords
            label="Keywords (Required)"
            tag-style="required"
            :values="keywordsRequired"
            @keywords-change="(val) => (keywordsRequired = val)"
            @clear="(val) => (keywordsRequired = val)"
          />
          <FilterKeywords
            label="Keywords (Desired)"
            tag-style="desired"
            :values="keywordsDesired"
            @keywords-change="(val) => (keywordsDesired = val)"
            @clear="(val) => (keywordsDesired = val)"
          />
          <FilterKeywords
            label="Education Keywords (Required)"
            tag-style="default"
            :values="educationKeywordsRequired"
            @keywords-change="(val) => (educationKeywordsRequired = val)"
            @clear="(val) => (educationKeywordsRequired = val)"
          />
          <FilterDropdown
            label="Headcount"
            :values="headCount"
            :options="headCountOptions"
            @select="(val) => (headCount = val)"
            @clear="(val) => (headCount = val)"
          />
          <FilterDropdown
            label="Employer Name"
            :values="employerName"
            :api-url="employerApi"
            :delay="500"
            value-key="company_name"
            label-key="company_name"
            clearable
            param-name="company"
            @select="(val) => (employerName = val)"
            @clear="(val) => (employerName = val)"
          />

          <FilterDropdown
            label="Past Employer Name"
            :values="pastEmployerName"
            :api-url="employerApi"
            :delay="500"
            value-key="company_name"
            label-key="company_name"
            clearable
            param-name="company"
            @select="(val) => (pastEmployerName = val)"
            @clear="(val) => (pastEmployerName = val)"
          />
          <FilterNumber
            label="Min Years in Current Role"
            :value="minYearsInCurrentRole > 0 ? minYearsInCurrentRole : undefined"
            @change="(val) => (minYearsInCurrentRole = val)"
          />
          <FilterNumber
            label="Min Years in Career"
            :value="minYearsInCareer > 0 ? minYearsInCareer : undefined"
            @change="(val) => (minYearsInCareer = val)"
          />
          <!-- <FilterRange label="Total role experience" /> -->
        </div>
        <div class="filter__footer">
          <Button type="button" variant="primary" @click="applyFilters"> Apply Filters</Button>

          <Button
            type="reset"
            variant="outline-secondary"
            class="text-secondary-600"
            @click="resetFilters"
          >
            <div class="flex justify-center">
              <Icon name="svg-trash" class="hidden lg:block w-5 h-5 me-2" />
              Clear Filters
            </div>
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from 'vue'
import axios from 'axios'

import Icon from '@/components/Icon/Icon.vue'
import Button from '@/components/Buttons/Button.vue'
import FilterDropdown from '@/components/Filters/FilterDropdown.vue'
import LocationFilterDropDown from '@/components/Filters/LocationFilterDropDown.vue'
import FilterNumber from '@/components/Filters/FilterNumber.vue'
import FilterKeywords from '@/components/Filters/FilterKeywords.vue'
import { useSearchStore } from '@/stores/search'

import { simplifiedYearConversion } from '@/utils/date'

interface IProps {
  open?: boolean
  table?: any
}

defineOptions({
  inheritAttrs: false
})

const headCountOptions = [
  { label: '1-10', value: 1 },
  { label: '10-50', value: 10 },
  { label: '50-200', value: 50 },
  { label: '200-500', value: 200 },
  { label: '500-1,000', value: 500 },
  { label: '1,000-5,000', value: 1000 },
  { label: '10,000+', value: 10000 }
]

const props = withDefaults(defineProps<IProps>(), {
  open: false
})
const emit = defineEmits(['close', 'apply'])

const searchStore = useSearchStore()

const industriesApi = `${import.meta.env.VITE_LOOKALIKES_API}/api/industries`
const employerApi = `${import.meta.env.VITE_LOOKALIKES_API}/api/company-auto-complete`
const locationApi = `${import.meta.env.VITE_LOOKALIKES_API}/api/location-auto-complete`
const jobTitleAutocompleteApi = `${import.meta.env.VITE_LOOKALIKES_API}/api/position-title-auto-complete`

const isOpen = ref<boolean>(false)
const industriesOptions = ref([])
const locationIsEmpty = ref(false)
const locationError = ref(false)
const locationErrorMessage = ref('')
const locations = ref(searchStore.searchPayload?.locations)
const locationRadius = ref(searchStore.searchPayload?.location_radius || 25)
const jobTitleIsEmpty = ref(false)

// const educationKeywordsDesired = ref(searchStore.searchPayload?.education_keywords_desired || [])

const keywordsDesired = ref(
  searchStore.searchPayload?.keywords
    ?.filter((keyword) => keyword.desired === true)
    .reduce((acc, curr) => {
      acc.push(...curr.keywords)
      return acc
    }, [])
    .map((keyword) => ({ label: keyword, value: keyword }))
)

const keywordsRequired = ref(
  searchStore.searchPayload?.keywords
    ?.filter((keyword) => keyword.desired === false)
    .reduce((acc, curr) => {
      acc.push(...curr.keywords)
      return acc
    }, [])
    .map((keyword) => ({ label: keyword, value: keyword }))
)

const educationKeywordsRequired = ref(
  searchStore.searchPayload?.education_keywords
    ?.filter((keyword) => keyword.desired === false)
    .reduce((acc, curr) => {
      acc.push(...curr.keywords)
      return acc
    }, [])
    .map((keyword) => ({ label: keyword, value: keyword }))
)

const titleInclude = searchStore.searchPayload?.title_include

const titleExclude = searchStore.searchPayload?.title_exclude

const titles = ref([...(titleInclude || []), ...(titleExclude || [])])

const industriesInclude = searchStore.searchPayload?.industries_include?.map((industry) => ({
  value: industry,
  label: industry,
  type: 'include'
}))

const industriesExclude = searchStore.searchPayload?.industries_exclude?.map((industry) => ({
  value: industry,
  label: industry,
  type: 'exclude'
}))

const industries = ref([...(industriesInclude || []), ...(industriesExclude || [])])

const headCountInclude = searchStore.searchPayload?.company_size_include
  ?.filter((size) => size !== 2) // size 1 and 2 are the same
  .map((size: number) => ({
    value: size,
    label: getLabelByValue(size),
    type: 'include'
  }))

const headCountExclude = searchStore.searchPayload?.company_size_exclude
  ?.filter((size) => size !== 2) // size 1 and 2 are the same
  .map((size: number) => ({
    value: size,
    label: getLabelByValue(size),
    type: 'exclude'
  }))

const headCount = ref([...(headCountInclude || []), ...(headCountExclude || [])])

const employerNameInclude = searchStore.searchPayload?.companies_include?.map((company) => ({
  value: company,
  label: company,
  type: 'include'
}))

const employerNameExclude = searchStore.searchPayload?.companies_exclude?.map((company) => ({
  value: company,
  label: company,
  type: 'exclude'
}))

const employerName = ref([...(employerNameInclude || []), ...(employerNameExclude || [])])

const pastEmployerNameInclude = searchStore.searchPayload?.past_employers_include?.map(
  (company) => ({
    value: company,
    label: company,
    type: 'include'
  })
)

const pastEmployerNameExclude = searchStore.searchPayload?.past_employers_exclude?.map(
  (company) => ({
    value: company,
    label: company,
    type: 'exclude'
  })
)

const pastEmployerName = ref([
  ...(pastEmployerNameInclude || []),
  ...(pastEmployerNameExclude || [])
])

const minYearsInCurrentRole = ref(
  simplifiedYearConversion(searchStore.searchPayload?.total_role_exp)
)
const minYearsInCareer = ref(simplifiedYearConversion(searchStore.searchPayload?.career_exp))

onMounted(async () => {
  const industriesRes = await axios.get(industriesApi)
  industriesOptions.value = industriesRes.data
})

function getLabelByValue(size: number) {
  const option = headCountOptions.find((option) => option.value === size)
  return option ? option.label : null
}

const jobTitleChanged = (val) => {
  jobTitleIsEmpty.value = titles.value.length === 0
  titles.value = val
}

const applyFilters = () => {
  window.scrollTo(0, 0)

  if (locations.value.length === 0) {
    locationIsEmpty.value = true
    locationErrorMessage.value = 'Please select at least one location'
    return
  }

  if (locations.value.filter((location) => location.type === 'include').length === 0) {
    locationError.value = true
    locationErrorMessage.value = 'Please select at least one location to include in your search'
    return
  }

  if (titles.value.length === 0) {
    jobTitleIsEmpty.value = true
    return
  }

  jobTitleIsEmpty.value = false
  locationIsEmpty.value = false
  locationError.value = false
  emit('apply', {
    title: titles.value.filter((title) => title.type === 'include').map((title) => title.label),
    title_include: titles.value.filter((title) => title.type === 'include'),
    title_exclude: titles.value.filter((title) => title.type === 'exclude'),
    industries_include: industries.value
      .filter((industry) => industry.type === 'include')
      .map((industry) => industry.label),
    industries_exclude: industries.value
      .filter((industry) => industry.type === 'exclude')
      .map((industry) => industry.label),
    company_size_include: headCount.value
      .filter((size) => size.type === 'include')
      .map((size) => size.value),
    company_size_exclude: headCount.value
      .filter((size) => size.type === 'exclude')
      .map((size) => size.value),
    companies_include: employerName.value
      .filter((company) => company.type === 'include')
      .map((company) => company.label),
    companies_exclude: employerName.value
      .filter((company) => company.type === 'exclude')
      .map((company) => company.label),
    past_employers_include: pastEmployerName.value
      .filter((company) => company.type === 'include')
      .map((company) => company.label),
    past_employers_exclude: pastEmployerName.value
      .filter((company) => company.type === 'exclude')
      .map((company) => company.label),
    locations: locations.value,
    location_radius: locationRadius.value,
    total_role_exp: minYearsInCurrentRole.value,
    career_exp: minYearsInCareer.value,
    industries: industriesOptions.value,
    keywords: [
      {
        keywords: keywordsRequired.value
          ? keywordsRequired.value.map((keyword) => keyword.label)
          : [],
        desired: false
      },
      {
        keywords: keywordsDesired.value
          ? keywordsDesired.value.map((keyword) => keyword.label)
          : [],
        desired: true
      }
    ],
    education_keywords: [
      {
        keywords: educationKeywordsRequired.value
          ? educationKeywordsRequired.value.map((keyword) => keyword.label)
          : [],
        desired: false
      }
    ]
  })
}

const resetFilters = () => {
  titles.value = []
  locations.value = []
  industries.value = []
  headCount.value = []
  employerName.value = []
  pastEmployerName.value = []
  minYearsInCurrentRole.value = undefined
  minYearsInCareer.value = undefined
}

const close = () => {
  isOpen.value = false
  emit('close')
}

watch(
  () => props.open,
  (openStatus) => {
    if (openStatus) {
      document.body.classList.add('max-lg:overflow-hidden')
    } else {
      document.body.classList.remove('max-lg:overflow-hidden')
    }
    isOpen.value = openStatus
  }
)
</script>

<style>
@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.filter {
  @apply hidden lg:block w-full px-4 md:px-8 lg:px-4 bg-secondary-50 overscroll-contain lg:animate-none;
  animation: slideFromBottom 0.5s ease-out forwards;

  .filter__header {
    @apply flex py-2.5 justify-center md:justify-between items-center md:border-b border-secondary-100 relative;

    .filter__title {
      @apply flex text-lg leading-6 lg:text-xl text-center md:text-start font-bold lg:font-semibold text-grey-900;
    }
  }

  .filter__body {
    @apply mt-4 lg:mt-6 lg:px-2 grid gap-3;

    .filter__section {
      @apply border-b last:border-b-0 pb-3 last:pb-0;

      .filter__section__name {
        @apply flex justify-between text-sm font-medium;
      }

      .filter__section__label {
        @apply text-secondary-900;
      }

      .filter__section__clear {
        @apply text-secondary-400 underline hover:no-underline ms-auto;
      }
    }
  }

  .filter__footer {
    @apply grid gap-4 mt-10;
  }
}

.filter.is-open {
  @apply z-[2147483600] block md:max-h-[100vh] md:h-[calc(100%-80px)] lg:max-h-none max-md:rounded-t-20 max-lg:fixed bottom-0 mt-auto inset-x-0 md:left-auto py-5 md:content-center max-lg:overflow-y-auto;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .filter {
    animation: slideFromRight 0.5s ease-out forwards;
  }
  .filter-shadow.is-open {
    box-shadow:
      0px 47px 47px 0px rgba(117, 117, 117, 0.09),
      0px 12px 26px 0px rgba(117, 117, 117, 0.1);
  }
}

.backdrop {
  @apply z-[2147483600] transition-opacity ease-linear duration-150 w-screen h-screen fixed inset-0 bg-secondary-950/50 md:bg-transparent;
}
</style>
