import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import axios from 'axios'
import * as Sentry from '@sentry/vue'
// import Hotjar from '@hotjar/browser'
import VueClickAwayPlugin from 'vue3-click-away'
import 'virtual:svg-icons-register'
// import VueGtm from '@gtm-support/vue-gtm'
import { useUserStore } from './stores/user'
const app = createApp(App)

axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      useUserStore().isLoggedIn &&
      (error.response.status === 401 || error.response.status === 419)
    ) {
      // Handle 401 Unauthorized error and 419 CSRF token mismatch
      useUserStore().logout()
      router.push({ name: 'login' })
    } else if (error.response && error.response.status === 403) {
      const redirectUrl = error.response.data.redirect_url
      if (redirectUrl) {
        router.push({ path: redirectUrl })
      }
    }
    return Promise.reject(error)
  }
)

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    app,
    dsn: 'https://3e8eadf6f20209d29bfa1490392a327e@o4504644646207488.ingest.us.sentry.io/4507610734854144',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^https:\/\/lookalikes.hrsignal.com\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })

  // Hotjar.init(5061404, 6)
}

app.use(createPinia())
app.use(VueClickAwayPlugin)
app.use(router)

app.mount('#app')
