<template>
  <SectionsLoader v-if="!candidateData || loading" />
  <div v-else>
    <div class="absolute left-0 right-0 top-22 h-[46px] items-center bg-grey-75 py-3 px-3 lg:px-5">
      <div class="flex flex-row justify-between">
        <div class="relative flex flex-col items-center group">
          <div
            v-if="!prevCandidateId"
            class="absolute bottom-full w-full transform mb-2 hidden group-hover:block z-50"
          >
            <div
              class="relative flex bg-grey-75 text-secondary-500 text-xs rounded py-1 px-2 shadow-lg w-full"
            >
              First candidate in results
            </div>
          </div>
          <Link
            variant="secondary"
            :disabled="!prevCandidateId"
            link-type="link"
            :to="{
              name: route.params.searchId ? 'candidate' : 'projectCandidate',
              params: route.params.searchId
                ? {
                    searchId: route.params.searchId,
                    searchResultId: prevCandidateId
                  }
                : {
                    projectId: route.params.projectId,
                    searchResultId: prevCandidateId
                  }
            }"
            class="font-normal no-underline flex px-1 gap-2"
            :class="{ 'cursor-not-allowed': !prevCandidateId }"
          >
            <Icon name="input-arrow-left" class="bg-transparent size-5 text-grey-600" />
            <span class="text-base text-grey-600 leading-[22px]">Previous Candidate</span>
          </Link>
        </div>
        <div class="relative flex flex-col items-center group">
          <div
            v-if="!nextCandidateId"
            class="absolute bottom-full w-full transform mb-2 hidden group-hover:block z-50"
          >
            <div
              class="relative flex bg-grey-75 text-secondary-500 text-xs rounded py-1 px-2 shadow-lg w-full"
            >
              Last candidate in results
            </div>
          </div>
          <Link
            variant="secondary"
            link-type="link"
            :disabled="!nextCandidateId"
            :to="{
              name: route.params.searchId ? 'candidate' : 'projectCandidate',
              params: route.params.searchId
                ? {
                    searchId: route.params.searchId,
                    searchResultId: nextCandidateId
                  }
                : {
                    projectId: route.params.projectId,
                    searchResultId: nextCandidateId
                  }
            }"
            class="font-normal no-underline flex px-1 gap-2"
            :class="{ 'cursor-not-allowed': !nextCandidateId }"
          >
            <span class="text-base text-grey-600 leading-[22px]">Next Candidate</span>
            <Icon name="input-arrow-right" class="text-grey-600 bg-transparent size-5" />
          </Link>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="md:p-4">
      <div class="flex flex-col pt-16">
        <div class="flex flex-col lg:flex-row justify-between">
          <div class="flex flex-col gap-4">
            <div class="flex flex-row gap-10 items-center justify-between order-1">
              <div class="flex gap-1 lg:gap-5 items-center">
                <a
                  :href="`https://www.linkedin.com/in/${candidateData.profileId}`"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="svg-linkedin" class="size-6 md:size-8" />
                </a>
                <span class="text-2xl md:text-3xl font-semibold text-grey-900 !leading-none">{{
                  candidateData.name
                }}</span>
              </div>
            </div>
            <div class="flex flex-row gap-2 items-center max-md:flex-wrap order-2 lg:order-3">
              <div class="flex gap-1 items-center md:me-4">
                <span class="text-secondary-600 text-base leading-5">{{
                  candidateData.workExperience[0].company_name
                }}</span>
              </div>
              <div class="flex gap-1 items-center">
                <Icon name="input-time" class="size-4 md:size-4 text-secondary-700" />
                <span class="text-secondary-700 text-sm leading-[19px]"
                  >Role:
                  {{
                    convertMonthsToYearsMonths(
                      candidateData.workExperience[0].total_experience_in_position
                    )
                  }}</span
                >
              </div>
              <div class="flex gap-1 items-center">
                <Icon name="input-time" class="size-4 md:size-4 text-secondary-700" />
                <span class="text-secondary-700 text-sm leading-[19px]"
                  >Company:
                  {{
                    convertMonthsToYearsMonths(
                      candidateData.workExperience[0].total_time_in_company
                    )
                  }}</span
                >
              </div>
              <div class="flex gap-1 items-center">
                <Icon name="input-time" class="size-4 md:size-4 text-secondary-700" />
                <span class="text-secondary-700 text-sm leading-[19px]"
                  >Career: {{ convertMonthsToYearsMonths(candidateData.careerExp) }}</span
                >
              </div>
            </div>
            <div class="flex flex-row flex-wrap gap-2 items-center order-3 lg:order-2">
              <div class="hidden lg:block">
                  <span class="text-secondary-900 text-md">
                    {{candidateData.workExperience[0].title}}
                  </span>
              </div>
              <div class="flex gap-1 items-center">
                <div
                  v-if="location"
                  class="flex bg-burgundy-50 rounded-lg text-burgundy-800 p-1 px-2 items-center gap-1 lg:max-h-[27px]"
                >
                  <Icon name="svg-location" class="size-4 stroke-burgundy-800 text-burgundy-800" />
                  <span class="text-sm flex-wrap leading-5"> {{location}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col max-md:hidden">
            <div class="flex flex-row gap-2 lg:justify-end items-center max-lg:pt-4">
              <AddToProjectButton
                position-classes="max-md:inset-x-0 lg:right-0 top-10"
                :projects="projects"
                :search-result-id="parseInt(route.params.searchResultId as string)"
                @addToProjectError="handleAddToProjectError"
                @addToProjectSuccess="handleAddToProjectSuccess"
                @projectCreated="handleProjectCreated"
                @projectCreationError="handleProjectCreationError"
              />
              <Button variant="outline-secondary" class="max-lg:order-2">
                <RouterLink :to="`/?tab=linkedin&liId=${candidateData.profileId}`" target="_blank">
                  Use as Archetype</RouterLink
                >
              </Button>
              <ShortlistButton
                v-if="route.params.searchId"
                class="order-1"
                :search-id="route.params.searchId"
                :search-result-id="route.params.searchResultId"
                @error="handleShortlistError"
              />
              <ShortlistButtonForProject
                v-if="route.params.projectId"
                class="order-1"
                :candidate-id="projectCandidateId"
                @error="handleShortlistError"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col lg:flex-row justify-between">
          <div class="flex flex-col pt-6 w-full lg:w-1/5 mb-4 md:mb-0">
            <div
              class="flex max-md:flex-col flex-row lg:flex-col rounded-lg bg-primary-50 gap-4 p-4 pb-6"
            >
              <div class="flex flex-row max-lg:w-1/2">
                <div class="flex flex-col gap-3">
                  <span class="text-lg font-semibold text-grey-900 leading-6">Found In</span>
                  <div class="flex flex-col gap-2">
                    <Loader v-if="loadingFoundInSection" class="w-10" />
                    <div v-else class="flex flex-col gap-2">
                      <div
                        v-for="search in foundInSearches"
                        :key="search.id"
                        class="flex flex-col gap-2"
                      >
                        <RouterLink
                          :to="{
                            name: 'searchResults',
                            params: { searchId: search.search_id }
                          }"
                        >
                          <span class="text-primary-700 text-sm hover:underline">
                            {{
                              search.search_type === 'title'
                                ? 'Job Title Search'
                                : 'Linkedin Archetype Search'
                            }}: “{{ search.title }}”
                          </span>
                        </RouterLink>
                        <span class="text-secondary-400 text-xs">{{
                          search.created_at_formatted
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-row max-lg:w-1/2">
                <div class="flex flex-col gap-3">
                  <span class="text-lg font-semibold text-grey-900 leading-5"
                    >Saved in Project(s)</span
                  >
                  <Loader v-if="loadingFoundInSection" class="w-10" />
                  <div v-else class="flex flex-col gap-2">
                    <div
                      v-for="project in savedInProjects"
                      :key="project.id"
                      class="flex flex-col gap-2"
                    >
                      <RouterLink
                        :to="{
                          name: 'projectCandidates',
                          params: { projectId: project.project_id }
                        }"
                        class="text-primary-700 text-sm hover:underline"
                      >
                        {{ project.project_name }}
                      </RouterLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full lg:w-4/5">
            <CandidateTabs
              :candidate-data="candidateData"
              class="max-sm:!px-0"
              :archetype-data="archetypeData"
              :candidate-id="route.params.candidateId"
              :search-result-id="route.params.searchResultId"
            >
              <template #actions>
                <div class="max-sm:hidden flex flex-row w-full items-center justify-between gap-6">
                  <div class="ml-5">
                    <RecentlyViewedButton :candidate-id="candidateData.profileId" />
                  </div>
                </div>
              </template>
            </CandidateTabs>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:hidden gap-4 mt-4">
        <div class="flex flex-row text-center">
          <AddToProjectButton
            class="w-full md:w-1/4"
            position-classes="bottom-12 left-0 max-md:inset-x-0"
            :projects="projects"
            :search-result-id="parseInt(route.params.searchResultId as string)"
            @addToProjectError="handleAddToProjectError"
            @addToProjectSuccess="handleAddToProjectSuccess"
            @projectCreated="handleProjectCreated"
            @projectCreationError="handleProjectCreationError"
          />grtbvgertbg
          <Button variant="outline-secondary" class="w-3/4">Use as Archetype</Button>
        </div>
        <div class="flex flex-row w-full">
          <ShortlistButton
            v-if="route.params.searchId"
            class="w-full"
            :search-id="route.params.searchId"
            :search-result-id="route.params.searchResultId"
            @error="handleShortlistError"
          />
          <ShortlistButtonForProject
            v-if="route.params.projectId"
            class="w-full"
            :candidate-id="projectCandidateId"
            @error="handleShortlistError"
          />
        </div>
      </div>
    </div>
    <Message
      v-if="shortlistError"
      class="fixed bottom-0 w-full h-15"
      type="error"
      :timeout="3000"
      :message="shortlistErrorMessage"
      @timeout="shortlistError = false"
    />

    <Message
      v-if="error"
      class="fixed bottom-0 w-full h-15"
      :timeout="5000"
      @timeout="error = false"
      type="error"
      :message="errorMessage"
    />
    <Message
      v-if="success"
      class="fixed bottom-0 w-full h-15"
      :timeout="3000"
      @timeout="success = false"
      type="success"
      :message="successMessage"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { captureException } from '@sentry/vue'
import { useRoute } from 'vue-router'
import axios from 'axios'

import CandidateTabs from '@/components/Modals/Candidate/components/CandidateTabs.vue'
import Link from '@/components/Link/Link.vue'
import Icon from '@/components/Icon/Icon.vue'
import Button from '@/components/Buttons/Button.vue'
import ShortlistButton from '@/components/Shortlist/ShortlistButton.vue'
import RecentlyViewedButton from '@/components/Candidate/Overview/RecentlyViewedButton.vue'
import SectionsLoader from '@/components/Loaders/SectionsLoader.vue'
import Message from '@/components/Message/Message.vue'
import Loader from '@/components/Loader.vue'
import AddToProjectButton from '@/components/Project/AddToProjectButton.vue'
import { default as ShortlistButtonForProject } from '@/views/Projects/ProjectsCandidatesPage/Components/ShortListButton.vue'

import { useSearchStore } from '@/stores/search'
import { useProjectStore } from '@/stores/project'

import { setRecentViewed } from '@/utils/recent-viewed-candidates'
import { convertMonthsToYearsMonths } from '@/utils'

const route = useRoute()

const searchStore = useSearchStore()
const projectStore = useProjectStore()

const loading = ref(false)
const candidateData = ref(null)
const archetypeData = ref(null)
const nextCandidateId = ref(null)
const prevCandidateId = ref(null)
const shortlistError = ref(false)
const shortlistErrorMessage = ref('')
const projects = ref([])
const error = ref(false)
const errorMessage = ref('')
const success = ref(false)
const successMessage = ref('')
const foundInSearches = ref([])
const savedInProjects = ref([])
const loadingFoundInSection = ref(false)
const projectCandidateId = computed(() => {
  return projectStore.getCandidates.find(
    (candidate) => candidate.search_result_id === parseInt(route.params.searchResultId as string)
  )?.project_candidate_id
})

const location = computed(() =>
  [candidateData.value?.city, candidateData.value?.state, candidateData.value?.country]
    .filter((x) => x)
    .join(', ')
)

onMounted(async () => {
  await fetchCandidateData()
  await fetchCandidates()
  await fetchProjects()
  await fetchFoundInSection()
})

watch(route, (to, from) => {
  fetchCandidateData()
  fetchCandidates()
  fetchFoundInSection()
})

const fetchProjects = async () => {
  const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`)
  projects.value = response.data
}

const fetchCandidateData = async () => {
  loading.value = true

  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/search-results/${route.params.searchResultId}/candidate/overview`
    )

    candidateData.value = res.data.candidateProfileData
    archetypeData.value = res.data.archetypeProfileData
    setRecentViewed(candidateData.value.profileId)
  } catch (error) {
    captureException(error)
  } finally {
    loading.value = false
  }
}

const fetchCandidates = async () => {
  if (route.params.searchId) {
    try {
      await searchStore.fetchSearchResults(parseInt(route.params.searchId as string))
      searchStore.setSearchId(parseInt(route.params.searchId as string))

      let currentIndex = searchStore.searchResults.results
        .sort((a, b) => a.ranking_order - b.ranking_order)
        .findIndex((obj) => obj.id === parseInt(route.params.searchResultId))
      console.log(currentIndex)
      prevCandidateId.value =
        currentIndex == 0 ? null : searchStore.searchResults.results[currentIndex - 1]?.id
      nextCandidateId.value =
        searchStore.searchResults.results.length - 1 === currentIndex
          ? null
          : searchStore.searchResults.results[currentIndex + 1]?.id
    } catch (error) {
      captureException(error)
    }
  } else {
    await projectStore.fetchCandidates(route.params.projectId as string)
    let currentIndex = projectStore.getCandidates.findIndex(
      (obj) => obj.search_result_id === parseInt(route.params.searchResultId as string)
    )
    nextCandidateId.value =
      projectStore.getCandidates.length - 1 === currentIndex
        ? null
        : projectStore.getCandidates[currentIndex + 1].search_result_id
    prevCandidateId.value =
      currentIndex === 0 ? null : projectStore.getCandidates[currentIndex - 1].search_result_id
  }
}

const fetchFoundInSection = async () => {
  loadingFoundInSection.value = true
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/search-results/${route.params.searchResultId}/candidate/found-in`
    )
    foundInSearches.value = response.data.foundInSearches
    savedInProjects.value = response.data.foundInProjects
  } catch (error) {
    error.value = true
    errorMessage.value =
      'Oops! Something went wrong getting the found in section, please contact support.'
  } finally {
    loadingFoundInSection.value = false
  }
}

const handleShortlistError = (error: Error) => {
  shortlistError.value = true
  shortlistErrorMessage.value = error.message
}
const handleProjectCreated = async () => {
  success.value = true
  successMessage.value = 'Project created successfully'

  const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`)
  projects.value = response.data
}

const handleProjectCreationError = (errMessage: string) => {
  error.value = true
  errorMessage.value = errMessage
}

const handleAddToProjectSuccess = async () => {
  success.value = true
  successMessage.value = 'Candidate added to project successfully'

  const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`)
  projects.value = response.data
}

const handleAddToProjectError = (errMessage: string) => {
  error.value = true
  errorMessage.value = errMessage
}
</script>
